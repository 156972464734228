import logo from '@netcurio-ui/assets/src/images/secondaryBlueNavyLogo.svg'
import coverImage from '@netcurio-ui/assets/src/imagesNewDesign/landingPageLogin.jpg'
import coverImageWebp from '@netcurio-ui/assets/src/imagesNewDesign/landingPageLogin.webp'
import { URLS } from '@netcurio-ui/common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioTextField,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import { Auth } from 'aws-amplify'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { ExpiredOrInvalidLink } from '../expiredOrInvalidLink'
import { RegisterCompanyModal } from '../registerCompanyModal/RegisterCompanyModal'
import styles from './passwordRecovery.module.scss'
import * as queries from './queries'

export const ResetPassword = () => {
	const { t } = useTranslation()
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [valuePin, setValuePin] = useState('')

	const [valuePassword, setValuePassword] = useState('')
	const [valuePasswordConfirm, setValuePasswordConfirm] = useState('')
	const [borderRedPin, setBorderRedPin] = useState(false)
	const [borderRedPassword, setBorderRedPassword] = useState(false)
	const [borderRedPassConfirm, setBorderRedPassConfirm] = useState(false)

	const [errorMsgRecovery, setErrorMsgRecovery] = useState('')
	const [errorMsgPassword, setErrorMsgPassword] = useState('')
	const [userEmailValidate, setUserEmailValidate] = useState('')
	const [showErrorMessage, setShowErrorMessage] = useState(false)
	const [showMissingMessage, setShowMissingMessage] = useState(false)
	const [validLink, setValidLink] = useState(false)

	const [isExpiredScreenOpen, setIsExpiredScreenOpen] = useState<boolean>(false)
	const [isInvalidScreenOpen, setIsInvalidScreenOpen] = useState<boolean>(false)

	useEffect(() => {
		queryDataToken()
	}, [])

	function queryDataToken() {
		setShowSpinner && setShowSpinner?.(true)
		const token = getUrlParameter('token')
		const client = connection()
		client
			.query({
				query: queries.USER_BY_RECOVERY_PASSWORD_TOKEN,
				variables: {
					recovery_password_token: token
				}
			})
			.then((result) => {
				setUserEmailValidate(result.data.UserByRecoveryPasswordToken.email)
				setValidLink(true)
				setShowSpinner?.(false)
			})
			.catch((error) => {
				if (
					error.graphQLErrors !== undefined &&
					error.graphQLErrors[0] !== undefined &&
					error.graphQLErrors[0].code === Constants.LINKS.EXPIRED_LINK
				) {
					setIsExpiredScreenOpen(true)
				} else {
					setIsInvalidScreenOpen(true)
				}

				setShowSpinner?.(false)
			})
	}

	function redirectToPassSuccess() {
		location.href = URLS.PASS_RESET_SUCCESSFUL
	}

	function hideErrorMsg() {
		setShowErrorMessage(false)
	}

	function hideErrorMsgPassword() {
		setShowMissingMessage(false)
	}

	function submitPasswordRecovery(e: React.FormEvent<HTMLFormElement>): false | void {
		setShowSpinner && setShowSpinner?.(true)
		e.preventDefault()
		const token = getUrlParameter('token')
		if (!valuePassword) {
			setErrorMsgRecovery('fieldNoFillText')
			setBorderRedPassword(true)
			setShowSpinner?.(false)
		}
		if (!valuePasswordConfirm) {
			setErrorMsgRecovery('fieldNoFillText')
			setBorderRedPassConfirm(true)
			setShowSpinner?.(false)
		}
		if (!valuePin) {
			setErrorMsgRecovery('fieldNoFillText')
			setBorderRedPin(true)
			setShowSpinner?.(false)
		}
		if (!valuePassword || !valuePasswordConfirm || !valuePin) {
			setShowErrorMessage(true)
			setShowMissingMessage(false)
			setErrorMsgRecovery('fieldNoFillText')
			setShowSpinner?.(false)
			return false
		}
		if (valuePassword !== valuePasswordConfirm) {
			setShowErrorMessage(true)
			setErrorMsgRecovery('validatePasswordFailConfirmText')
			setBorderRedPassConfirm(true)
			setBorderRedPassword(true)
			setShowSpinner?.(false)
			return false
		}
		if (!valuePassword.match(Constants.REGEX.PASSWORD)) {
			setShowErrorMessage(false)
			setShowMissingMessage(true)
			setErrorMsgPassword('validatePasswordFailText')
			setBorderRedPassConfirm(true)
			setShowSpinner?.(false)
			return false
		}
		hideErrorMsgPassword()
		hideErrorMsg()
		const client = connection()
		Auth.forgotPasswordSubmit(userEmailValidate, valuePin, valuePassword)
			.then(() => {
				client
					.mutate({
						mutation: queries.INVALIDATE_RESET_PASSWORD_TOKEN,
						variables: {
							email: userEmailValidate,
							recovery_password_token: token
						}
					})
					.then(() => {
						setShowSpinner?.(false)
						redirectToPassSuccess()
					})
					.catch(() => {
						setShowErrorMessage(true)
						setShowMissingMessage(false)
						setErrorMsgPassword('failedText')
						setValidLink(true)
						setShowSpinner?.(false)
					})
			})
			.catch(() => {
				setShowErrorMessage(true)
				setShowMissingMessage(false)
				setErrorMsgRecovery('failedText')
				setValidLink(true)
				setShowSpinner?.(false)
			})
	}

	function renderComponent() {
		if (isExpiredScreenOpen) {
			return <ExpiredOrInvalidLink isExpired redirect="passwordRecovery" />
		} else if (isInvalidScreenOpen) {
			return <ExpiredOrInvalidLink isExpired />
		} else if (validLink) {
			return (
				<NetcurioGrid
					container
					alignContent="flex-start"
					justifyContent="flex-start"
					className={styles.body}
				>
					<NetcurioGrid item xs={1} />
					<NetcurioGrid item xs={2}>
						<form onSubmit={submitPasswordRecovery}>
							<div className={styles.buttonsPosition}>
								<div>
									<img src={logo} className={styles.logo} alt="Netcurio" />
								</div>
								<div className={styles.titleMargin}>
									<span className={styles.loginLabel}>{t('resetPasswordTitleText')}</span>
								</div>
								<div className={styles.paddingTopXS}>
									<p className={styles.infoPasswordText}>{t('validatePin')}</p>
									<NetcurioTextField
										error={borderRedPin}
										size="small"
										fullWidth
										label={t('placeHolderPin')}
										value={valuePin}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setValuePin(e.target.value.replace(' ', ''))
										}}
									/>
								</div>
								<div className={styles.paddingTopXS}>
									<p className={styles.infoPasswordText}>{t('validatePasswordFailText')}</p>
									<NetcurioTextField
										type="password"
										error={borderRedPassword}
										size="small"
										fullWidth
										label={t('newPassword')}
										value={valuePassword}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setValuePassword(e.target.value.replace(' ', ''))
										}}
									/>
								</div>
								<div className={styles.paddingTopXS}>
									<NetcurioTextField
										type="password"
										error={borderRedPassConfirm}
										size="small"
										fullWidth
										label={t('confirmPassword')}
										value={valuePasswordConfirm}
										onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
											setValuePasswordConfirm(e.target.value.replace(' ', ''))
										}}
									/>
								</div>
								{showErrorMessage && (
									<div onChange={() => hideErrorMsg()}>
										<p className={styles.msgerrorP}>{t(errorMsgRecovery)}</p>
									</div>
								)}
								{showMissingMessage && (
									<div>
										<div onChange={() => hideErrorMsgPassword()}>
											<p className={styles.msgerrorP}>{t(errorMsgPassword)}</p>
										</div>
									</div>
								)}
								<div className={styles.paddingTopMedium}>
									<NetcurioButton fullWidth variant="contained" size="small" type="submit">
										{t('resetPassText')}
									</NetcurioButton>
								</div>
							</div>
						</form>
					</NetcurioGrid>
					<NetcurioGrid item xs={1} />
					<NetcurioGrid item xs={8}>
						<link rel="preload" as="image" href={coverImageWebp} />
						<picture className={styles.coverImage}>
							<source type="image/webp" srcSet={coverImageWebp} />
							<source type="image/png" srcSet={coverImage} />
							<img src={coverImage} alt="" />
						</picture>
					</NetcurioGrid>
					<RegisterCompanyModal />
				</NetcurioGrid>
			)
		} else {
			return <div />
		}
	}

	return renderComponent()
}
