import { dateFormatterLong, getCurrentUser, IBranchOffices } from '@netcurio-ui/common'
import {
	NetcurioAutocomplete,
	NetcurioTextField,
	NetcurioTooltip,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import dayjs from 'dayjs'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ComponentQueries from '../../components/queries'
import { BranchOffice } from '../../types'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import styles from './newConsumption.module.scss'

interface Props {
	setBranchOffice: (newSelectedBranchOffice: BranchOffice) => void
	emptyBranchOffice: boolean
	removeBranchOffice: () => void
	selectedBranchOffice?: BranchOffice
	deliveryAddress?: string
}

export default function HeaderInformation({
	setBranchOffice,
	emptyBranchOffice,
	selectedBranchOffice,
	deliveryAddress
}: Props) {
	const { t } = useTranslation()
	const user = getCurrentUser()
	const client = connection()
	const currentDate = dayjs()

	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [allBranchOffices, setAllBranchOffices] = useState<Array<BranchOffice>>([])
	const [showError, setShowError] = useState<boolean>(false)
	const [branchOfficesLoading, setBranchOfficesLoading] = useState<boolean>(true)

	useEffect(() => {
		getAllBranches()
	}, [])

	useEffect(() => {
		setShowError(emptyBranchOffice)
	}, [emptyBranchOffice])

	function getAllBranches() {
		setShowSpinner && setShowSpinner(true)
		setBranchOfficesLoading(true)
		client
			.query({
				query: ComponentQueries.BRANCH_OFFICES,
				variables: {
					company: user.company.rfc
				}
			})
			.then((result) => {
				const allBranchOfficesRes = result.data.BranchOffices
				if (allBranchOfficesRes.length === 1) {
					setBranchOffice(allBranchOfficesRes[0])
				}
				setAllBranchOffices(allBranchOfficesRes)
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				expiredToken(errorCode)
			})
			.finally(() => {
				setBranchOfficesLoading(false)
				setShowSpinner(false)
			})
	}

	const getBranchOfficesLabel = (branchOffice: IBranchOffices) => branchOffice.description
	const validateBranchOfficesValue = (option: IBranchOffices, value: IBranchOffices) =>
		option.id === value.id

	return (
		<div className={styles.newConsumptionContainerOrderDetails}>
			<div className={styles.headerContainerNewConsumption}>
				<div className={'displayFlex'}>
					<p className={styles.titleHeaderNewConsumption}>{t('titleNewConsumption')}</p>
				</div>
			</div>
			<div className={styles.containerDataNewConsumption}>
				<div className={styles.titleSectionConsumption}>{t('newConsumptionInfo')}</div>
				<div className={styles.alignModulesNewConsumption}>
					<div className={styles.halfScreenLeftConsumption}>
						<div className={styles.selectorFieldNewConsumption}>
							<NetcurioTextField
								label={t('createdByText')}
								value={`${user.name} ${user.lastname}`}
								fullWidth
								height="small"
								variant="outlined"
								disabled
							/>
						</div>
					</div>
					<div className={styles.halfScreenConsumption}>
						<NetcurioTextField
							label={t('creationDate')}
							value={dateFormatterLong.format(currentDate.toDate())}
							fullWidth
							height="small"
							variant="outlined"
							disabled
						/>
					</div>
				</div>
				<div className={styles.bodyDataNewConsumption}>
					<div className={styles.halfScreenLeftConsumption}>
						<div className={styles.selectorFieldNewConsumption}>
							<NetcurioAutocomplete
								label={t('titleBranchOfficeSelect')}
								height="small"
								size="small"
								placeholder={t('branchOfficeSelector')}
								variant="outlined"
								options={allBranchOffices}
								getOptionLabel={getBranchOfficesLabel}
								isOptionEqualToValue={validateBranchOfficesValue}
								value={selectedBranchOffice}
								onSelectValue={setBranchOffice}
								loading={branchOfficesLoading}
								error={emptyBranchOffice}
							/>
						</div>
					</div>
					<NetcurioTooltip title={deliveryAddress}>
						<div className={styles.halfScreenConsumption}>
							<NetcurioTextField
								label={t('titleAddress')}
								value={deliveryAddress}
								fullWidth
								height="small"
								variant="outlined"
								disabled
							/>
						</div>
					</NetcurioTooltip>
				</div>
				<div className={showError ? 'display-block' : 'display-none'}>
					<p className={'not-filled-message-new-po'}>{t('missingFields')}</p>
				</div>
			</div>
		</div>
	)
}
