import { fillModal } from '../../components/dialogModal/fillModal'

export function modalDefaultCompanyBlockOrUnsubscribed(question: string) {
	const defaultCompanyBlockOrUnsubscribed = new fillModal(
		'selectCompany',
		'',
		question,
		'selectCompany',
		'closeActionText',
		'',
		''
	)
	return [defaultCompanyBlockOrUnsubscribed]
}

export function modalDefaultRegisterCompany() {
	const defaultRegisterCompany = new fillModal(
		'registerCompany',
		'registerCompanyTitle',
		'setRequiredData',
		'registerCompany',
		'registerCompanyButton',
		'',
		''
	)
	return [defaultRegisterCompany]
}
