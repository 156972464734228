import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	ShowRootElementsContext,
	TextInputFilter
} from '@netcurio-ui/components'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './goodsReceiptList.module.scss'
import { objectHeaderArray } from './tableParameterArray'
import TableRow from './tableRow'
import { TypesDataTable, TypesMainTable, TypesStyleMainTable } from './types'

export const GoodsReceiptTable = ({
	deleteRange,
	filterContainerBar,
	totalRows,
	rowsActual,
	closeFilterContainerBar,
	dataFiltersArray,
	deleteFilterActive,
	filteringGoodsReceipts,
	dataGoodsReceipt,
	setActivePagination,
	showBarLoader,
	setShowBarLoader
}: TypesMainTable): React.ReactElement => {
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [styleMainTable, setStyleMainTable] = useState<TypesStyleMainTable>({
		mainContainerHeight: undefined,
		mainTableHeight: undefined,
		activeHeight: true
	})
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		textHeader: styles.textHeaderListGR,
		dateFilter: 'display-none',
		textFilter: 'display-none',
		actualField: 'created_at',
		filterValue: undefined,
		initDateErrorText: '',
		finalDateErrorText: '',
		firstLineFilterColor: '',
		secondLineFilterColor: '',
		minAmount: undefined,
		maxAmount: undefined,
		initRange: undefined,
		finalRange: undefined,
		minRange: undefined,
		maxRange: undefined,
		initDate: undefined,
		finalDate: undefined,
		deleteRange: false
	})

	useEffect(() => {
		setShowBarLoader(false)
		const finalHeight: number = window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT
		const mainHeight: number = window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: finalHeight,
			mainContainerHeight: mainHeight
		}))
		setFillFields(objectHeaderArray())
	}, [])

	useEffect(() => {
		if (totalRows && rowsActual)
			window.document
				.getElementsByClassName(styles.infoGoodsReceipt)[0]
				.addEventListener('scroll', handlePagination)

		return () => {
			const element: Element = window.document.getElementsByClassName(styles.infoGoodsReceipt)[0]
			element && element.removeEventListener('scroll', handlePagination)
		}
	}, [totalRows, rowsActual])

	useEffect(() => {
		if (deleteRange && dataTable.deleteRange) {
			if (dataTable.actualField === 'created_at') {
				setDataTable((prevState) => ({
					...prevState,
					deleteRange: false,
					initRange: undefined,
					finalRange: undefined
				}))
			}
		}
		if (filterContainerBar && styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight - 40,
				mainContainerHeight: prevState.mainContainerHeight - 40,
				activeHeight: false
			}))
		} else if (!filterContainerBar && !styleMainTable.activeHeight) {
			setStyleMainTable((prevState) => ({
				mainTableHeight: prevState.mainTableHeight + 40,
				activeHeight: true,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
		}
		setResultNotFound(totalRows < 1)
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [deleteRange, filterContainerBar, totalRows, dataTable])

	const showFilters = (field: string, fieldStyle: string) => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		let activeFilter: string
		switch (filterActual) {
			case 'dateFilter':
				classActualField = 'goods-receipts-' + fieldStyle + '-filter'
				activeFilter = dataTable.dateFilter
				break
			case 'textFilter':
				classActualField = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}
		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)

			if (verifyMainContainer)
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - 40,
					mainContainerHeight: prevState.mainContainerHeight - 40
				}))

			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: undefined,
				initRange: undefined,
				finalRange: undefined,
				dateFilter: 'display-none',
				textFilter: 'display-none',
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textheaderOnClick,
				[filterActual]: classActualField
			}))
		} else if (dataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				textHeader: styles.textHeaderListGR,
				initRange: undefined,
				finalRange: undefined,
				[filterActual]: 'display-none'
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + 40,
			mainContainerHeight: prevState.mainContainerHeight + 40
		}))
		setDataTable((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			textFilter: 'display-none',
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange?: { [key: string]: number | Dayjs }
	) => {
		setShowSpinner && setShowSpinner(true)
		let initRange: number | Dayjs = dataTable.initRange,
			finalRange: number | Dayjs = dataTable.finalRange
		if (objectRange) {
			if (objectRange.initRange) initRange = objectRange.initRange
			if (objectRange.finalRange) finalRange = objectRange.finalRange
		}
		if (sortField !== '') {
			filteringGoodsReceipts(
				0,
				sortField,
				order,
				fieldFilter,
				undefined,
				undefined,
				undefined,
				undefined
			)
		} else {
			filteringGoodsReceipts(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'

		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handlePagination = () =>
		listHelper.handlePagination(
			styles.infoGoodsReceipt,
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)

	const handleTextFilterChange = (evt: ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		const value: string = evt.target.value
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (evt: ChangeEvent<HTMLInputElement>) => {
		listHelper.handleFilterKeyDown(
			typeof evt === 'object' ? { ...evt, target: evt.target as HTMLInputElement } : evt,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			'',
			''
		)
	}

	const emptyFilterField = () => {
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}

	return (
		<div className={styles.generalTable}>
			<div className={styles.headerMainTableGR}>
				{fillFields.map((item) => (
					<HeaderTable
						key={item.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={item}
					/>
				))}
			</div>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					objectType={Constants.LISTS.GR}
					dataFiltersArray={dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={deleteFilterActive}
				/>
			)}
			<div
				className={styles.tableInformationWhiteStyle}
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className={styles.infoGoodsReceipt} style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataGoodsReceipt).map((key) => (
						<TableRow key={key} informationTable={dataGoodsReceipt[parseInt(key)]} />
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
