import { NetcurioAlert, Severity, ShowRootElementsContext } from '@netcurio-ui/components'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { connection } from '../../../../utilities/connection'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import { getUrlParameter } from '../../../../utilities/getUrlParameter'
import * as queries from '../../queries'

/**
 * @param acceptedRequest <boolean>: If `true`, the function "acceptCreditMemoRequest" is triggered.
 * @param closeMainModal <void>>: Callback fired when the component requests to be closed.
 * @param emptyError <string>: If there was a server error and you want to return to the mainView, "emptyError" will reset the NetcurioAlert.
 * @param resetBtnValue <void>: Callback fired to reset setAcceptRCM state in AcceptRCM component.
 */
export interface AcceptRequestCreditMemoProps {
	acceptedRequest: boolean
	closeMainModal: () => void
	emptyServerError: string
	resetBtnValue: () => void
}

export const AcceptRequestCreditMemo = ({
	acceptedRequest,
	closeMainModal,
	emptyServerError,
	resetBtnValue
}: AcceptRequestCreditMemoProps) => {
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [serverErrorMessage, setServerErrorMessage] = useState<string>('')

	useEffect(() => {
		if (acceptedRequest) {
			acceptCreditMemoRequest()
			resetBtnValue()
		}
	}, [acceptedRequest])

	useEffect(() => {
		setServerErrorMessage(emptyServerError)
	}, [emptyServerError])

	const acceptCreditMemoRequest = () => {
		setShowSpinner && setShowSpinner(true)
		const client = connection()
		const id = getUrlParameter('requestforcreditmemo')
		const customer = getUrlParameter('customer')
		client
			.mutate({
				mutation: queries.ACCEPT_CREDIT_MEMO_REQUEST,
				variables: {
					id,
					customer
				}
			})
			.then(() => {
				setServerErrorMessage('')
				setShowSpinner(false)
				closeMainModal()
				location.reload()
			})
			.catch((error) => {
				console.error(error)
				const idCode = showErrorComponent(error)
				if (!expiredToken(idCode)) {
					setServerErrorMessage(t('errorCodeText', { idCode }))
				}
				setShowSpinner(false)
			})
	}
	return (
		<div>
			{serverErrorMessage && (
				<NetcurioAlert severity={Severity.Error}>{t(serverErrorMessage)}</NetcurioAlert>
			)}
			<p>{t('acceptCreditMemoDetail')}</p>
		</div>
	)
}
