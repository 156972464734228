import { DocumentNode } from 'graphql'
import gql from 'graphql-tag'

export const INVOICES_DETAIL: DocumentNode = gql`
	query Invoice($uuid: ID!) {
		Invoice(uuid: $uuid) {
			uuid
			serie
			folio
			status {
				key
				value
			}
			sender {
				rfc
				name
			}
			receiver {
				rfc
				name
			}
			reference
			reference_type
			purchase_order {
				id
				customer {
					rfc
				}
				customer_reference
			}
			consumption {
				id
				customer {
					rfc
				}
				customer_reference
			}
			uploaded_by {
				email
				name
				lastname
			}
			modified_by_supplier {
				email
				name
				lastname
			}
			modified_by_customer {
				email
				name
				lastname
			}
			payment_method
			currency
			subtotal
			iva
			total
			date
			proposed_payment_date
			payment_date
			date_voided
			bank_reference
			updated_at
			created_at
			items {
				position
				concept
				quantity
				unit_price
				net_amount
				tax
				error
				purchase_order_item {
					code
					name
					unit_price
					tax
				}
				consumption_item {
					code
					description
					unit_price
					tax
				}
				reference_position
				error
				code
				price_validation
				tax_validation
				currency_validation
				quantity_validation
				missing_merchandise_validation
			}
			payment_complements {
				uuid
				serie
				folio
				date
				created_at
				payments {
					position
					date
					currency
					amount
					items {
						uuid
						paid_amount
						pending_balance
						invoice {
							uuid
							serie
							folio
						}
					}
				}
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
			invoice_documents {
				link
				document_type {
					code
					description
				}
			}
			error {
				key
				value
			}
			override_by {
				email
				name
				lastname
			}
			override_on
			override_message
			ieps
			isr
			cancellation_reason
			folder_uuid
			folder {
				created_by
				created_at
				files {
					name
					description
					url
					created_by
					created_at
					file_size
				}
			}
			sat_validation
			accounting_period_validation
			cost_center_key
			expense_type_key
			expense_subtype_key
			discount
			iva_ret
		}
	}
`
