import { Filter, Modal, Roles, URLS } from '@netcurio-ui/common'
import { ShowRootElementsContext } from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import listHelper from '../../utilities/listHelper'
import UserInformation from '../../utilities/userInformation'
import { HeaderButtons } from './HeaderButtons'
import { CatchedErrorModalRequestForCreditMemoList } from './Modals/CatchedErrorModalRequestForCreditMemoList'
import * as queries from './queries'
import { RequestForCreditMemoTable } from './requestForCreditMemoTable'
import { objectStatusFilterArray } from './tableParameterArray'
import { DataRFCM, FilterToApply, RequestForCreditMemo, RFCM, UpdateFilter } from './types'

export const RequestForCreditMemoList = () => {
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [userRole, setUserRole] = useState<Roles>()
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const initialSort = 'created_at'
	const history = useHistory<string>()
	const containerHeightCompensation = 95
	const heightRow = 45
	const additionalRow = 2
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])

	const [requestForCreditMemo, setRequestForCreditMemo] = useState<RequestForCreditMemo>({
		orderList: Constants.ORDER.DESC,
		fieldList: initialSort,
		totalRows: undefined,
		limitRows: undefined,
		rowsActual: undefined,
		dataRequestForCreditMemo: {},
		dataFilters: {},
		dataFiltersArray: [],
		filterContainerBar: false,
		deleteRange: false,
		filtersOfTypeStatus: {
			status: {
				list: objectStatusFilterArray(),
				numSelectOptions: 5,
				dataFilter: ''
			}
		},
		actualFilterData: initialSort
	})
	const [modal, setModal] = useState<Modal>({
		errorCode: undefined
	})
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [isPaginationActive, setIsPaginationActive] = useState<boolean>(true)

	useEffect(() => {
		const finalHeight: number = window.innerHeight - containerHeightCompensation
		const numberRows: number = Math.round(finalHeight / heightRow + additionalRow)
		setRequestForCreditMemo((prevState) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
		setUserRole(UserInformation.getCompanyRole())
		setShowSpinner && setShowSpinner(true)
	}, [])

	useEffect(() => {
		if (requestForCreditMemo.limitRows !== undefined)
			filteringRequestForCreditMemo(0, initialSort, '', initialSort)
	}, [requestForCreditMemo.limitRows])

	useEffect(() => {
		if (activePagination && isPaginationActive) {
			setActivePagination(false)
			setIsPaginationActive(false)
			setShowBarLoader(true)
			filteringRequestForCreditMemo(
				requestForCreditMemo.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, isPaginationActive])

	const filteringRequestForCreditMemo = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter?: string,
		initRange?: number | Dayjs,
		finalRange?: number | Dayjs,
		filterRemove?: string
	) => {
		let fieldListData: string = requestForCreditMemo.fieldList,
			orderListData: string = requestForCreditMemo.orderList,
			columnFilterActual: string = requestForCreditMemo.actualFilterData,
			currentList: DataRFCM = {
				...requestForCreditMemo.dataRequestForCreditMemo
			}

		if (sortOrder !== '') {
			orderListData = sortOrder
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortField) {
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}

		let typeFilterActual: string, initRangeActual: number | Dayjs, finalRangeActual: number | Dayjs
		const valueFilterActual: string = valueFilter
		switch (columnFilterActual) {
			case 'id':
			case 'customer_reference':
			case 'created_by':
			case 'supplier':
			case 'customer':
			case 'modified_by_supplier':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
		}
		if (skip > 0) {
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				rowsActual: prevState.rowsActual + prevState.limitRows
			}))
		} else {
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataCreditMemo: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)

		queryFilteringRequestForCreditMemo(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number | Dayjs,
		finalRangeActual: number | Dayjs,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFilterToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			requestForCreditMemo.dataFilters,
			requestForCreditMemo.filtersOfTypeStatus
		) as FilterToApply

		const objUpdate: UpdateFilter = resultFilterToApply.objectForStateUpdate
		setRequestForCreditMemo((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))
		if (objUpdate.filtersOfTypeStatus) {
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				filtersOfTypeStatus: objUpdate.filtersOfTypeStatus
			}))
		}
		return resultFilterToApply.filterToApply
	}

	const queryFilteringRequestForCreditMemo = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: DataRFCM
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.REQUEST_FOR_CREDIT_MEMOS,
				variables: {
					limit: requestForCreditMemo.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip
				const data: DataRFCM = { ...currentList },
					obj: Array<RFCM> = result.data.RequestForCreditMemos.list
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				setRequestForCreditMemo((prevState) => ({
					...prevState,
					totalRows: result.data.RequestForCreditMemos.total,
					dataRequestForCreditMemo: data,
					filtersOfTypeStatus: {
						...prevState.filtersOfTypeStatus,
						status: {
							...prevState.filtersOfTypeStatus.status,
							dataFilter: prevState.filtersOfTypeStatus.status.dataFilter || ''
						}
					}
				}))
				setIsPaginationActive(true)
				setShowSpinner(false)
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setRequestForCreditMemo((prevState) => ({
				...prevState,
				dataCreditMemo: {},
				filtersOfTypeStatus: {
					...prevState.filtersOfTypeStatus,
					status: {
						...prevState.filtersOfTypeStatus.status,
						dataFilter: ''
					}
				}
			}))
			setIsPaginationActive(true)
			setModal({
				errorCode: errorCode
			})
		}
		setShowSpinner(false)
		setShowBarLoader(false)
	}

	const downloadReportExcel = () => {
		if (Object.keys(requestForCreditMemo.dataRequestForCreditMemo).length > 0) {
			setShowSpinner && setShowSpinner(true)
			const copyFilterToApply: Array<Filter> = JSON.parse(
				JSON.stringify(requestForCreditMemo.dataFiltersArray)
			)
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body: string = JSON.stringify({
				sort_field: requestForCreditMemo.fieldList,
				sort_order: requestForCreditMemo.orderList,
				filters: copyFilterToApply
			})
			listHelper
				.generateReportList(body, 'credit-memo-requests')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						setShowSpinner(false)
					} else {
						const error: Error = await res.json()
						handleError(error)
						setShowSpinner(false)
					}
				})
				.catch(handleError)
		}
	}

	const closeContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringRequestForCreditMemo, initialSort)
		setRequestForCreditMemo((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) => {
		listHelper.deleteFilterActive(
			indexObject,
			requestForCreditMemo.dataFilters,
			closeContainerBar,
			filteringRequestForCreditMemo
		)
	}

	return (
		<>
			<HeaderButtons
				onExport={() => downloadReportExcel()}
				userRole={userRole}
				onNewInvoice={() => history.push(URLS.NEW_REQUEST_FOR_CREDIT_MEMO)}
			/>

			<RequestForCreditMemoTable
				userRole={userRole}
				filterContainerBar={requestForCreditMemo.filterContainerBar}
				dataRequestForCreditMemo={requestForCreditMemo.dataRequestForCreditMemo}
				closeFilterContainerBar={closeContainerBar}
				dataFiltersArray={requestForCreditMemo.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				filtersOfTypeStatus={requestForCreditMemo.filtersOfTypeStatus}
				filteringRequestForCreditMemo={filteringRequestForCreditMemo}
				deleteRange={requestForCreditMemo.deleteRange}
				totalRows={requestForCreditMemo.totalRows}
				rowsActual={requestForCreditMemo.rowsActual}
				setActivePagination={setActivePagination}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<CatchedErrorModalRequestForCreditMemoList open={!!modal.errorCode} errorCode={modal.errorCode} />
		</>
	)
}
