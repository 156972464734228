import { AUTH, RELATION_KEY_URL, URLS, getCurrentUser } from '@netcurio-ui/common'
import Tracker from '@openreplay/tracker'
import { useQuery } from '@tanstack/react-query'
import { Auth } from 'aws-amplify'
import { useEffect } from 'react'
import { fetchEnvironment } from '../api/fetch-environment'
import ERROR from '../utilities/constants/error'
import { validateMulticompany } from '../utilities/redirectHelper'
import UserInformation from '../utilities/userInformation'

interface AuthProps {
	onTokenExpired?: () => void
}

export const useAuth = ({ onTokenExpired }: AuthProps = {}) => {
	const { data, isSuccess } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})
	let trackerId = undefined

	if (isSuccess) {
		trackerId = data.OPENREPLAY_KEY
	}
	const tracker = trackerId
		? new Tracker({
				projectKey: trackerId
			})
		: undefined

	const tokenHasExpired = (idToken: { payload: { iat: number } }) => {
		const now = new Date()
		const issuedAt = idToken.payload.iat
		const expiresOn = (issuedAt + AUTH.TOKEN_VALIDITY_MINUTES * 60) * 1000
		return expiresOn < now.getTime()
	}

	useEffect(() => {
		const currentUser = getCurrentUser()
		if (tracker && currentUser && currentUser.email) {
			tracker.start()
		}
	}, [tracker])

	const signOut = () => {
		Auth.signOut()
			.then(() => {
				localStorage.clear()
				location.href = URLS.LOGIN
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				if (tokenHasExpired(user.signInUserSession.idToken)) {
					if (window.location.pathname !== URLS.LOGIN) {
						signOut()
					}
					onTokenExpired?.()
					return
				}
				const currentUser = getCurrentUser()
				if (!currentUser) {
					signOut()
					return
				}
				let companySelected = currentUser.company
				const companiesUnblocked = UserInformation.getCompaniesUnblocked()
				const userInfo = currentUser
				const haveDefaultCompany = UserInformation.getDefaultCompany()
				const appURLS: { [key: string]: string } = URLS
				let URLvalid = false

				if (tracker && currentUser && currentUser.email) {
					tracker.setUserID(currentUser.email)
				}

				const pathname = window.location.pathname

				for (const key in appURLS) {
					if (appURLS[key] === pathname) {
						URLvalid = true
						break
					}
				}
				if (
					!URLvalid &&
					(pathname.includes(appURLS.ADMINISTRATION + '/') ||
						pathname.includes(appURLS.REPORT_LIST + '/') ||
						pathname.includes(appURLS.SAMPLE + '/'))
				) {
					URLvalid = true
				}

				if (!URLvalid) {
					location.href = appURLS.NOT_FOUND
					return
				}
				switch (pathname) {
					case '/':
					case appURLS.LOGIN:
					case appURLS.REGISTER:
					case appURLS.RESET_PASSWORD:
					case appURLS.PASS_RESET_SUCCESSFUL:
					case appURLS.PASSWORD_RECOVERY:
						if (companySelected) {
							location.href = companySelected.homepage
								? appURLS[
										RELATION_KEY_URL[
											companySelected.homepage as keyof typeof RELATION_KEY_URL
										]
									]
								: appURLS.PO_LIST
						} else {
							if (haveDefaultCompany) {
								companySelected = UserInformation.getCompanySelected(
									companiesUnblocked,
									haveDefaultCompany
								)
								if (companySelected !== undefined) {
									UserInformation.setCurrentUser(userInfo, companySelected)
									location.href = companySelected.homepage
										? appURLS[companySelected.homepage]
										: appURLS.PO_LIST
								} else {
									validateMulticompany(companiesUnblocked, undefined)
								}
							} else {
								validateMulticompany(companiesUnblocked, undefined)
							}
						}
				}
			})
			.catch((err) => {
				if (err !== ERROR.AWS_NOT_AUTHENTICATED_ERROR) {
					console.error(err)
				}
				const pathname = window.location.pathname
				if (
					pathname !== URLS.LOGIN &&
					pathname !== URLS.REGISTER &&
					pathname !== URLS.RESET_PASSWORD &&
					pathname !== URLS.PASSWORD_RECOVERY &&
					pathname !== URLS.PASS_RESET_SUCCESSFUL &&
					pathname !== URLS.REGISTER_COMPANY &&
					pathname !== '/'
				) {
					sessionStorage.setItem('internalUrl', window.location.href)
					location.href = URLS.LOGIN
				}
			})
	}, [onTokenExpired])
}
