import Constants from '../../../../utilities/constants'
import { Invoice, InvoiceItemValidation, PaymentComplement } from '../../../../types'
import { Validations } from '../../../../types/Validations'
import {
	MdRefPaletteErrorAMain,
	NetcurioRefPrimaryAMain,
	ColorBaseWhite,
	ColorBaseGraySilver
} from '@netcurio-ui/design-tokens/dist/_variables'

export const defaultFileViewer = { open: false, titleText: '', fileSrc: '' }
export const defaultValuesInvoice: Invoice = {
	uuid: '',
	serie: '',
	folio: '',
	status: {
		key: '',
		value: ''
	},
	sender: {},
	receiver: {},
	reference_type: undefined,
	reference: undefined,
	purchase_order: undefined,
	consumption: undefined,
	uploaded_by: {},
	payment_method: '',
	currency: '',
	subtotal: 0,
	ieps: 0,
	iva: 0,
	total: 0,
	date: undefined,
	proposed_payment_date: undefined,
	payment_date: 0,
	payment_way: undefined,
	type: undefined,
	error: undefined,
	updated_at: 0,
	items: [],
	date_voided: undefined,
	modified_by_customer: undefined,
	modified_by_supplier: undefined,
	bank_reference: undefined,
	date_payment_registered: undefined,
	date_canceled: undefined,
	created_at: undefined,
	payment_complements: [],
	comments: [],
	invoice_documents: []
}

export const themeButtonComeBack = {
	fontSize: '1.2rem',
	color: Constants.COLORS.GREY_PEBBLE,
	borderRadius: '0.5rem',
	textTransform: 'inherit',
	padding: '0 1rem',
	border: `0.1rem solid ${Constants.COLORS.RED_COLOR_ERROR_BORDER}`,
	height: '3rem',
	backgroundColor: Constants.COLORS.BASE_WHITE,
	minWidth: 'auto',
	'&:hover': {
		color: 'white',
		background: Constants.COLORS.RED_COLOR_ERROR_BORDER,
		border: `0.1rem solid ${Constants.COLORS.RED_COLOR_ERROR_BORDER}`
	}
}

export const themeButtonHeader = {
	fontSize: '1.2rem',
	color: Constants.COLORS.GREY_PEBBLE,
	borderRadius: '.5rem',
	textTransform: 'inherit',
	padding: '0 1rem',
	backgroundColor: Constants.COLORS.BASE_WHITE,
	border: `0.1rem solid ${Constants.COLORS.GREEN_INVOICES}`,
	'&:hover': {
		color: 'white',
		background: Constants.COLORS.GREEN_INVOICES,
		border: `0.1rem solid ${Constants.COLORS.GREEN_INVOICES}`
	}
}

export const themeButtonReview = {
	marginLeft: '2.2rem',
	color: Constants.COLORS.REVIEW_BUTTON,
	fontWeight: '600'
}

export const defaultPaymentSelected: PaymentComplement = {
	uuid: '',
	sender: { name: '', rfc: '' },
	receiver: { name: '', rfc: '' },
	uploaded_by: { name: '', lastname: '', email: '' },
	created_at: undefined,
	status: { key: '', value: '' },
	void_reason: '',
	payments: [],
	comments: []
}

export const themeButtonRegisterPayment = {
	color: Constants.COLORS.BASE_WHITE,
	background: Constants.COLORS.GREEN_INVOICES,
	minWidth: 'auto',
	'&:hover': {
		background: Constants.COLORS.GREEN_INVOICES,
		opacity: '0.8'
	},
	marginBottom: '1rem'
}

export const themePaymentComplementButton = {
	backgroundColor: NetcurioRefPrimaryAMain,
	color: `${ColorBaseWhite} !important`,
	'&:hover': {
		backgroundColor: NetcurioRefPrimaryAMain
	}
}

export const themePaymentComplementButtonDisabled = {
	backgroundColor: ColorBaseGraySilver,
	color: `${ColorBaseWhite} !important`,
	'&:hover': {
		backgroundColor: ColorBaseGraySilver
	}
}

export const defaultValuesValidations: Validations = {
	accountingPeriodValidation: { value: InvoiceItemValidation.OK },
	currencyValidation: { positions: [], value: InvoiceItemValidation.OK },
	priceValidation: { positions: [], value: InvoiceItemValidation.OK },
	quantityValidation: { positions: [], value: InvoiceItemValidation.OK },
	taxValidation: { positions: [], value: InvoiceItemValidation.OK },
	missingMerchandiseValidation: { positions: [], value: InvoiceItemValidation.OK },
	satValidation: { value: InvoiceItemValidation.OK }
}

export const themeIconRowError = {
	cursor: 'pointer',
	color: MdRefPaletteErrorAMain
}
