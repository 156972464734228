import { getCompany, Roles } from '@netcurio-ui/common'
import { CheckBoxesSection, NetcurioButton, ShowRootElementsContext } from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connection } from '../../../../../utilities/connection'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import UserInformation from '../../../../../utilities/userInformation'
import { CancelConfigurationModal } from './Modals/CancelConfigurationModal'
import { CatchedErrorModalNotificationTab } from './Modals/CatchedErrorModalNotificationTab'
import { notificationCustomer, notificationSupplier } from './notificationSettings'
import styles from './notificationsTab.module.scss'
import * as queries from './queries'
import { CheckboxColumns, CheckBoxGroup, INotificationsTabProps } from './types'

export const NotificationsTab = ({
	cancelConfig,
	stayInNotificationsTab,
	leaveNotificationsTab,
	changeInNotification
}: INotificationsTabProps) => {
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const { t } = useTranslation()
	const { setShowSpinner } = useContext(ShowRootElementsContext)

	const [userRole] = useState<string>(UserInformation.getCompanyRole())
	const [company, setCompany] = useState(getCompany())
	const [showButtons, setShowButtons] = useState<boolean>(true)
	const [somethingChange, setSomethingChange] = useState<boolean>(false)
	const [disabledButton, setDisabledButton] = useState<boolean>(true)
	const [gridHeight] = useState<string>(window.innerHeight - 234 + 'px')
	const [checkboxesColumns, setCheckboxesColumns] = useState<CheckboxColumns>(
		userRole === Roles.SUPPLIER ? notificationSupplier(company) : notificationCustomer(company)
	)
	const [errorCode, setErrorCode] = useState<string>('')

	const checkboxesColumnsSorted = useMemo(() => {
		return Object.fromEntries(
			Object.entries(checkboxesColumns)
				.filter(([key]) => key !== 'Tickets')
				.sort(([, a], [, b]) => t(a.title).localeCompare(t(b.title)))
		)
	}, [checkboxesColumns, t])

	const changeSectionCheckboxesValues = (value: boolean, id: string): void => {
		const tmpCheckboxesColumns = checkboxesColumns
		Object.keys(tmpCheckboxesColumns[id].checkBoxRows).forEach((key) => {
			tmpCheckboxesColumns[id].checkBoxRows[key].value = value
		})
		setCheckboxesColumns(tmpCheckboxesColumns)

		if (!showButtons) {
			setShowButtons(true)
		}
		if (!somethingChange) {
			changeInNotification(true)
			setSomethingChange(true)
			setDisabledButton(false)
		}
	}

	const changeCheckboxValue = (value: boolean, columnId: string, rowId: string): void => {
		const tmpCheckboxesColumns = { ...checkboxesColumns }
		tmpCheckboxesColumns[columnId].checkBoxRows[rowId].value = value
		if (!showButtons) {
			setShowButtons(true)
		}
		if (!somethingChange) {
			changeInNotification(true)
			setSomethingChange(true)
			setDisabledButton(false)
		}
		setCheckboxesColumns(tmpCheckboxesColumns)
	}

	//save settings of notifications
	const saveNotificationsSettings = (): void => {
		setShowSpinner && setShowSpinner(true)
		const variables: {
			[key: string]: boolean
		} = {}
		Object.keys(checkboxesColumns).forEach((columnKey) => {
			Object.keys(checkboxesColumns[columnKey].checkBoxRows).forEach((rowKey) => {
				variables[rowKey] = checkboxesColumns[columnKey].checkBoxRows[rowKey].value
			})
		})
		client
			.mutate({
				mutation: queries.UPDATE_NOTIFICATIONS,
				variables
			})
			.then(async () => {
				await UserInformation.updateUserInfo()
				setCompany(getCompany())
				changeInNotification(false)
				setShowButtons(false)
				setSomethingChange(false)
				setDisabledButton(true)
			})
			.catch(handleError)
			.finally(() => setShowSpinner(false))
	}

	const restoreDefaultNotifications = async () => {
		setShowSpinner && setShowSpinner(true)
		await client
			.mutate({
				mutation: queries.RESTORE_DEFAULT_NOTIFICATIONS
			})
			.then(async () => {
				await UserInformation.updateUserInfo()
				setCompany(getCompany())
				const tempCompany = getCompany()
				const tmpCheckboxesColumns = { ...checkboxesColumns }
				Object.keys(tmpCheckboxesColumns).forEach((columnKey) => {
					Object.keys(tmpCheckboxesColumns[columnKey].checkBoxRows).forEach((rowKey) => {
						tmpCheckboxesColumns[columnKey].checkBoxRows[rowKey].value = tempCompany[rowKey]
					})
				})
				changeInNotification(false)
				setSomethingChange(false)
				setDisabledButton(true)
				setCheckboxesColumns(tmpCheckboxesColumns)
			})
			.catch(handleError)
			.finally(() => setShowSpinner(false))
	}

	const cancelNotificationsChanges = (): void => {
		const tempCheckboxesColumns = { ...checkboxesColumns }
		Object.keys(tempCheckboxesColumns).forEach((columnKey) => {
			Object.keys(tempCheckboxesColumns[columnKey].checkBoxRows).forEach((rowKey) => {
				tempCheckboxesColumns[columnKey].checkBoxRows[rowKey].value = company[rowKey]
			})
		})
		changeInNotification(false)
		setCheckboxesColumns(tempCheckboxesColumns)
		setSomethingChange(false)
		setDisabledButton(true)
	}

	const showMessageInfoSaved = () => {
		if (!showButtons) {
			setTimeout(() => {
				setShowButtons(true)
			}, 3500)
			return (
				<div id={'message-user-data-saved'} className={styles.messageDataSavedContainer}>
					<div className={'message-data-saved-text'}>{t('changeSave')}</div>
				</div>
			)
		}
	}

	const sortCheckBoxRows = (checkBoxRows: CheckBoxGroup) =>
		Object.fromEntries(
			Object.entries(checkBoxRows).sort(([, a], [, b]) => t(a.name).localeCompare(t(b.name)))
		)

	const handleError = (error: Error) => {
		const newErrorCode = showErrorComponent(error)
		if (!expiredToken(newErrorCode)) {
			setErrorCode(newErrorCode)
		}
	}

	return (
		<div className={styles.cardBackground}>
			<div className={styles.headerProfileTitle}>
				<div className={styles.notificationsTabHeader}>
					<p className={styles.notificationsTabHeaderText}>
						{t('notificationsPreferencesSubtitle')}
					</p>
				</div>
				{showButtons && (
					<div className={styles.displayFlex}>
						<NetcurioButton
							variant="text"
							color="secondary"
							size={'small'}
							className={styles.button}
							onClick={() => restoreDefaultNotifications()}
						>
							<span> {t('resetPassText')} </span>
						</NetcurioButton>
						<NetcurioButton
							disabled={disabledButton}
							variant="text"
							size={'small'}
							color="secondary"
							className={styles.button}
							onClick={() => cancelNotificationsChanges()}
						>
							<span> {t('cancelButton')} </span>
						</NetcurioButton>
						<NetcurioButton
							disabled={disabledButton}
							variant="outlined"
							size={'small'}
							onClick={() => saveNotificationsSettings()}
							className={styles.button}
						>
							<span> {t('saveButton')} </span>
						</NetcurioButton>
					</div>
				)}
				{showMessageInfoSaved()}
			</div>
			<div className={styles.notificationsColumnsContainer} style={{ height: gridHeight }}>
				{Object.entries(checkboxesColumnsSorted).map(([key, { checkBoxRows, title }]) => (
					<CheckBoxesSection
						key={key}
						id={key}
						title={title}
						changeSectionCheckboxesValues={changeSectionCheckboxesValues}
						checkBoxes={sortCheckBoxRows(checkBoxRows)}
						changeCheckboxValue={changeCheckboxValue}
					/>
				))}
			</div>
			<CatchedErrorModalNotificationTab open={!!errorCode} errorCode={errorCode} />
			<CancelConfigurationModal
				open={cancelConfig && somethingChange}
				close={() => stayInNotificationsTab()}
				cancel={() => leaveNotificationsTab()}
			/>
		</div>
	)
}
