import {
	NetcurioCollapse,
	NetcurioGrid,
	NetcurioIcons,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip
} from '@netcurio-ui/components'
import classNames from 'classnames'
import React, { FC, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { InvoiceItem as InvoiceItemType, InvoiceItemValidation } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import Formatter from '../../../../../utilities/formatter'
import { themeIconRowError } from '../../utilities/defaultValues'
import styles from './InvoiceItem.module.scss'

interface InvoiceItemProps {
	invoiceItem: InvoiceItemType
	referenceType?: string | null
	renderColumnWithError: boolean
}

const totalColumns = 9

export const InvoiceItem: FC<InvoiceItemProps> = ({
	invoiceItem: {
		position,
		concept,
		quantity,
		unit_price,
		net_amount,
		tax,
		error,
		code,
		purchase_order_item,
		consumption_item,
		price_validation,
		tax_validation,
		currency_validation,
		quantity_validation,
		missing_merchandise_validation
	},
	referenceType,
	renderColumnWithError
}): ReactElement => {
	const { t } = useTranslation()
	const isInfoItemPO: boolean =
		referenceType === Constants.DOCUMENT_TYPE.PURCHASE_ORDER ||
		referenceType === Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER
	const isErrorPriceValidation: boolean = price_validation === InvoiceItemValidation.NOT_OK
	const isErrorTaxValidation: boolean = tax_validation === InvoiceItemValidation.NOT_OK
	const isErrorCurrencyValidation: boolean = currency_validation === InvoiceItemValidation.NOT_OK
	const isErrorQuantityValidation: boolean = quantity_validation === InvoiceItemValidation.NOT_OK
	const isMissingMerchandiseValidation: boolean =
		missing_merchandise_validation === InvoiceItemValidation.NOT_OK
	const isRowWithError: boolean =
		isErrorPriceValidation ||
		isErrorTaxValidation ||
		isErrorCurrencyValidation ||
		isErrorQuantityValidation ||
		isMissingMerchandiseValidation
	const errorsItem: Array<Record<string, boolean>> = [
		{ priceNotMatch: isErrorPriceValidation },
		{ taxNotMatch: isErrorTaxValidation },
		{ currencyNotMatch: isErrorCurrencyValidation },
		{ quantityInvoiceVsConsumption: isErrorQuantityValidation },
		{ missingMerchandiseValidation: isMissingMerchandiseValidation }
	]
	const [open, setOpen] = useState<boolean>(false)

	const getErrorMessage = (): string => {
		const errorCount: number = errorsItem.reduce((count: number, error: Record<string, boolean>) => {
			const value: boolean = Object.values(error)[0]
			return count + (value ? 1 : 0)
		}, 0)
		if (errorCount === 0) {
			return ''
		}
		const errorString: string = Object.keys(
			errorsItem.find((error: Record<string, boolean>): boolean => Object.values(error)[0])
		)[0]
		const message: string = t(errorString)
		if (errorCount > 1) {
			return message.concat(' ', t('errors', { count: errorCount - 1 }))
		}
		return message
	}

	return (
		<>
			<NetcurioTableRow isDetailRow rowWithError={isRowWithError || error}>
				{renderColumnWithError && (
					<NetcurioTableCell align="center">
						<NetcurioGrid
							container
							alignItems="center"
							justifyContent="space-around"
							width="100%"
						>
							{isRowWithError && (
								<NetcurioIcons.ErrorOutline
									onClick={() => {
										setOpen(!open)
									}}
									sx={themeIconRowError}
								/>
							)}
						</NetcurioGrid>
					</NetcurioTableCell>
				)}
				<NetcurioTableCell align="center">
					<p>{position}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{concept}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.codeDescription(code)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{quantity}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.currency.format(unit_price)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.currency.format(net_amount)}</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p>{Formatter.percent.format(tax)}</p>
				</NetcurioTableCell>

				{referenceType && (purchase_order_item || consumption_item) && (
					<NetcurioTableCell align="center">
						<div className={styles.textColumnMainTableInvoice}>
							<div className={styles.purchaseOrderAssociated}>
								<p
									className={classNames(
										styles.itemDescriptionOverflow,
										styles.multilineInfo
									)}
								>{`${t('itemCode')} ${
									isInfoItemPO
										? purchase_order_item?.code ?? ''
										: consumption_item?.code ?? ''
								}`}</p>
								<NetcurioTooltip
									title={
										isInfoItemPO
											? purchase_order_item?.name
											: consumption_item?.description
									}
									placement="top"
									followCursor={true}
								>
									<div className={styles.textInLine}>
										<p
											className={classNames(
												styles.itemDescriptionOverflow,
												styles.multilineInfo
											)}
										>
											{isInfoItemPO
												? purchase_order_item?.name
												: consumption_item?.description}
										</p>
									</div>
								</NetcurioTooltip>
								<p
									className={classNames(
										styles.itemDescriptionOverflow,
										styles.multilineInfo
									)}
								>{`${t('itemUnitPrice')} ${Formatter.currency.format(
									isInfoItemPO
										? purchase_order_item?.unit_price
										: consumption_item?.unit_price
								)}`}</p>
								<p
									className={classNames(
										styles.itemDescriptionOverflow,
										styles.multilineInfo
									)}
								>{`${t('ivaText')} ${Formatter.percent.format(
									isInfoItemPO ? purchase_order_item?.tax : consumption_item?.tax
								)}`}</p>
							</div>
						</div>
					</NetcurioTableCell>
				)}
			</NetcurioTableRow>
			<NetcurioTableRow>
				<NetcurioTableCell sx={{ padding: 0 }} colSpan={totalColumns}>
					<NetcurioCollapse in={open} timeout="auto" unmountOnExit>
						<NetcurioGrid
							container
							direction="column"
							alignContent="center"
							alignItems="start"
							justifyContent="center"
							className={styles.containerCollapse}
						>
							<NetcurioGrid
								item
								alignItems="center"
								alignContent="center"
								margin="0.5rem"
								className={styles.itemCollapse}
							>
								<NetcurioIcons.ErrorOutline />
								<span>{getErrorMessage()}</span>
							</NetcurioGrid>
						</NetcurioGrid>
					</NetcurioCollapse>
				</NetcurioTableCell>
			</NetcurioTableRow>
		</>
	)
}
