import gql from 'graphql-tag'
import { DocumentNode } from 'graphql'

export const PURCHASE_ORDER_DETAIL: DocumentNode = gql`
	query PurchaseOrderDetail($id: ID!, $customer: ID!) {
		PurchaseOrderDetail(id: $id, customer: $customer) {
			id
			supplier {
				rfc
				name
			}
			status {
				key
				value
			}
			type {
				key
				value
			}
			branch_office
			modified_by {
				name
				lastname
				email
			}
			invoices {
				serie
				folio
				uuid
				total
				date
				status {
					key
					value
				}
			}
			goodsReceipts {
				id
				customer {
					rfc
				}
				created_at
				customer_reference
				created_by {
					email
					name
					lastname
				}
				items {
					position
					product_description
					quantity
					unit
					purchase_order_item {
						storage_location
					}
					batches {
						id
						batch
						quantity
						goods_receipt
						customer
						goods_receipt_position
					}
				}
			}
			status {
				key
				value
			}
			created_at
			delivery_address_line_1
			delivery_address_line_2
			delivery_address_country
			delivery_address_state
			delivery_address_postal_code
			currency
			total
			updated_at
			created_at
			created_by {
				name
				lastname
				email
			}
			items {
				position
				name
				code
				net_value
				unit_price
				reference_price
				price_change_reason
				requested_amount
				confirmed_amount
				delivered_quantity
				delivery_proposal_date
				required_delivery_date
				unit
				status {
					key
					value
				}
				tax
				rejected_reason {
					key
					value
				}
				storage_location
				requires_confirmation
			}
			date_rejected
			date_confirmed
			date_last_delivery
			rejected_reason {
				key
				value
			}
			customer {
				rfc
				name
			}
			customer_reference
			user_reference
			attachment
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`

export const CREATE_PURCHASE_ORDER_COMMENT: DocumentNode = gql`
	mutation createPurchaseOrderComment($purchase_order: ID!, $customer: ID!, $text: String!) {
		createPurchaseOrderComment(purchase_order: $purchase_order, customer: $customer, text: $text) {
			code
			message
		}
	}
`

export const PURCHASE_ORDER_COMMENTS: DocumentNode = gql`
	query PurchaseOrder($id: ID!, $customer: ID!) {
		PurchaseOrder(id: $id, customer: $customer) {
			id
			customer {
				rfc
			}
			comments {
				uuid
				text
				created_by {
					email
					name
					lastname
				}
				created_at
			}
		}
	}
`
