import gql from 'graphql-tag'

export const COMPANY_SETTINGS = gql`
	query CompanySettings($rfc: String!) {
		CompanySettings(rfc: $rfc) {
			tolerance
			affect_consignment_stock
			validate_catalogue_on_po_creation
			validate_confirmed_on_gr_creation
			process_invoice_in_external_system
			validate_quantity_invoice_vs_consumption
			validate_sat
			standalone_invoice_workflow_enabled
			cost_center_required
			expense_type_required
		}
	}
`
