import {
	Company,
	Filter,
	getLanguage,
	parseDateWLanguage,
	Roles,
	tableFillSelectionFilter
} from '@netcurio-ui/common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	ShowRootElementsContext,
	TagsFilter,
	TextInputFilter
} from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import { Dayjs } from 'dayjs'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { showModalExpiredSession } from '../../../../../components/expiredSessionMessage'
import { HeaderTable } from '../../../../../components/HeaderTable/HeaderTable'
import { Order } from '../../../../../types'
import { connection } from '../../../../../utilities/connection'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import listHelper from '../../../../../utilities/listHelper'
import { tableFillHeader } from '../../../../../utilities/tableFillHeader'
import UserInformation from '../../../../../utilities/userInformation'
import styles from '../../../adminConsole.module.scss'
import * as queries from './queries'
import { objectHeaderArray, objectStatusFilterArray } from './tableParameters'
import TableRow from './tableRow'

interface ResultFiltersToApplyObj {
	filterContainerBar: boolean
	dataFilters: { [name: string]: FiltersToApply }
	dataFiltersArray: Filter[]
	dataStatusFilter: string
	filtersOfTypeStatus: TypeStatusFilters
	deleteRange: boolean
}

interface TypeStatusFilters {
	status: {
		list: Array<tableFillSelectionFilter>
		numSelectOptions: number
		dataFilter?: string
	}
}

interface EnrollmentInvitations {
	[name: string]: {
		id: string
		status: {
			value: string
		}
		created_at: string
		customer: Company
		supplier: Company
	}
}

interface FiltersToApply {
	column_name: string
	type: string
	init_date?: number | string | Dayjs
	final_date?: number | string | Dayjs
	value?: string
}

export const EnrollmentsList = ({
	setDataFilterArray
}: {
	setDataFilterArray: (dataFilter: Filter[], orderFilter: Order) => void
}) => {
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const [orderList, setOrderList] = useState<string>(Constants.ORDER.DESC)
	const [fieldList, setFieldList] = useState<string>('')
	const [actualFilterData, setActualFilterData] = useState<string>('')
	const [actualField, setActualField] = useState<string>('')
	const [totalRows, setTotalRows] = useState<number>(1)
	const [limitRows, setLimitRows] = useState<number>(14)
	const [rowsActual, setRowsActual] = useState<number>(0)
	const [dataEnrollmentInvitations, setDataEnrollmentInvitations] = useState<EnrollmentInvitations>({})
	const [resultNotFound, setResultNotFound] = useState<boolean>(false)
	const [dataFilters, setDataFilters] = useState<{}>({})
	const [filterContainerBar, setFilterContainerBar] = useState<boolean>(false)
	const [dataFiltersArray, setDataFiltersArray] = useState<Filter[]>([])
	const [textHeader, setTextHeader] = useState<string>(styles.headerTableDefault)
	const [textFilter, setTextFilter] = useState<string>('display-none')
	const [statusFilter, setStatusFilter] = useState<string>('display-none')
	const [dateFilter, setDateFilter] = useState<string>('display-none')
	const [fillStatusInputFilter, setFillStatusInputFilter] =
		useState<tableFillSelectionFilter[]>(objectStatusFilterArray())
	const [filterValue, setFilterValue] = useState<string>(undefined)
	const [initDate, setInitDate] = useState<Dayjs>(undefined)
	const [finalDate, setFinalDate] = useState<Dayjs>(undefined)
	const [filtersOfTypeStatus, setFiltersOfTypeStatus] = useState<TypeStatusFilters>({
		status: {
			list: objectStatusFilterArray(),
			numSelectOptions: 0,
			dataFilter: ''
		}
	})
	const [initRange, setInitRange] = useState<string>('')
	const [finalRange, setFinalRange] = useState<string>('')
	const [minRange, setMinRange] = useState<string>('')
	const [maxRange, setMaxRange] = useState<string>('')
	const [headerButtons, setHeaderButtons] = useState<tableFillHeader[] | undefined>()
	const initReferenceText = 'init'
	const finReferenceText = 'final'
	const initialSort = 'created_at'
	const language = getLanguage()
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const isCustomer = UserInformation.getCompanyRole() === Roles.CUSTOMER
	let isMounted = true

	showModalExpiredSession()

	useEffect(() => {
		if (isMounted) {
			setShowSpinner && setShowSpinner(true)
			const finalRowsHeight: number =
				window.innerHeight - Constants.USER_SETTINGS.ADMIN_HEIGHT_TABLES.ROWS_INFO_HEIGHT
			const numberRows: number = Math.round(finalRowsHeight / 44) + 1
			setLimitRows(numberRows)
			setRowsActual(numberRows)
			filteringEnrollments(0, initialSort, '', initialSort, undefined, undefined, undefined, undefined)
		}
		return () => {
			isMounted = false
		}
	}, [])

	window.onscroll = function () {
		if (window.innerHeight + Math.round(window.scrollY) >= document.body.offsetHeight) {
			setShowBarLoader(true)
			if (rowsActual < totalRows) {
				filteringEnrollments(
					rowsActual,
					initialSort,
					'',
					initialSort,
					undefined,
					undefined,
					undefined,
					undefined
				)
			} else {
				setShowBarLoader(false)
			}
		}
	}

	useEffect(() => {
		setHeaderButtons(objectHeaderArray(isCustomer))
	}, [isCustomer])

	useEffect(() => {
		if (filtersOfTypeStatus.status.dataFilter === 'return') {
			if (fillStatusInputFilter.length !== objectStatusFilterArray().length) {
				setFillStatusInputFilter(objectStatusFilterArray())
			}
			if (filterValue !== undefined) {
				setFilterValue(undefined)
			}
		} else if (filtersOfTypeStatus.status.dataFilter !== '' && filtersOfTypeStatus.status.dataFilter) {
			fillStatusInputFilter.push(new tableFillSelectionFilter(filtersOfTypeStatus.status.dataFilter))
		}
	}, [filtersOfTypeStatus.status.dataFilter])

	useEffect(() => {
		setResultNotFound(totalRows < 1)
	}, [totalRows])

	function activeFilterToApply(
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: string,
		finalRangeActual: string,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	) {
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			dataFilters,
			filtersOfTypeStatus
		)
		const obj = resultFiltersToApply.objectForStateUpdate as ResultFiltersToApplyObj
		setFilterContainerBar(obj['filterContainerBar'])
		setDataFilters(obj['dataFilters'])
		setDataFiltersArray(obj['dataFiltersArray'])
		obj['filtersOfTypeStatus'] && setFiltersOfTypeStatus(obj['filtersOfTypeStatus'])
		return resultFiltersToApply.filterToApply
	}

	function getEnrollments(
		fieldListData: string,
		orderListData: string,
		filterToApply: FiltersToApply[],
		skip: number,
		currentList: EnrollmentInvitations
	) {
		const copyFilterToApply = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.EnrollmentInvitations,
				variables: {
					limit: limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				if (isMounted) {
					setShowSpinner(false)
					setShowBarLoader(false)
					let interCount = skip
					const queryTotal = result.data.EnrollmentInvitations.total
					const data: EnrollmentInvitations = { ...currentList }
					const userRole = UserInformation.getCompanyRole()
					const obj = result.data.EnrollmentInvitations.list.map(
						(invitation: EnrollmentInvitations) => ({
							...invitation,
							company: userRole === 'supplier' ? invitation.customer : invitation.supplier
						})
					)
					for (const keyName in obj) {
						interCount++
						data[interCount] = obj[keyName]
					}
					setTotalRows(queryTotal)
					setDataEnrollmentInvitations(data)
					setFiltersOfTypeStatus({
						status: {
							...filtersOfTypeStatus.status,
							dataFilter: ''
						}
					})
					setShowSpinner(false)
					setShowBarLoader(false)
				}
			})
			.catch((error) => {
				if (isMounted) {
					setShowSpinner(false)
					setShowBarLoader(false)
					const errorCode = showErrorComponent(error)
					expiredToken(errorCode)
					setDataEnrollmentInvitations({})
					setFiltersOfTypeStatus({
						status: {
							...filtersOfTypeStatus.status,
							dataFilter: ''
						}
					})
				}
			})
	}

	function deleteDateRange() {
		setInitRange(undefined)
		setFinalRange(undefined)
	}

	function deleteFilterActive(indexObject: string) {
		if (indexObject === 'created_at') {
			deleteDateRange()
		}
		listHelper.deleteFilterActive(indexObject, dataFilters, closeFilterContainerBar, filteringEnrollments)
	}

	const closeFilterContainerBar = () => {
		deleteDateRange()
		listHelper.closeFilterContainerBar(filteringEnrollments, initialSort)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValueParam: string,
		objectRange: { initRange?: string; finalRange?: string }
	) => {
		setShowSpinner && setShowSpinner(true)
		let initRangeL = initRange
		let finalRangeL = finalRange
		const minRangeL = minRange
		const maxRangeL = maxRange
		if (minRangeL) initRangeL = minRangeL
		if (maxRangeL) finalRangeL = maxRangeL
		if (objectRange) {
			if (objectRange.initRange) initRangeL = objectRange.initRange
			if (objectRange.finalRange) finalRangeL = objectRange.finalRange
		}
		if (sortField !== '') {
			filteringEnrollments(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringEnrollments(
				0,
				sortField,
				order,
				fieldFilter,
				filterValueParam,
				initRangeL,
				finalRangeL,
				undefined
			)
		}
	}

	function filteringEnrollments(
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter: string,
		initRangeP: string,
		finalRange: string,
		filterRemove: string
	) {
		let fieldListData = fieldList
		let orderListData = orderList
		let columnFilterActual = actualFilterData

		if (sortOrder !== '') {
			orderListData = sortOrder
			setOrderList(sortOrder)
		}
		if (sortField) {
			setFieldList(sortField)
			fieldListData = sortField
		}
		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setActualFilterData(elementFilterActual)
		}
		let typeFilterActual
		let initRangeActual
		let finalRangeActual
		const valueFilterActual = valueFilter

		switch (columnFilterActual) {
			case 'id':
			case 'customer_rfc':
			case 'customer':
			case 'supplier_rfc':
			case 'supplier':
				typeFilterActual = 'wildcard'
				break
			case 'status':
				typeFilterActual = 'exact_match'
				break
			case 'created_at':
				typeFilterActual = 'Dayjs'
				initRangeActual = initRangeP
				finalRangeActual = finalRange
				break
		}
		let currentList = { ...dataEnrollmentInvitations }
		if (skip > 0) {
			setRowsActual(rowsActual + limitRows)
		} else {
			setRowsActual(limitRows)
			setDataEnrollmentInvitations({})
			currentList = {}
		}
		const filterToApply = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		setDataFilterArray(filterToApply, {
			sortField: fieldListData,
			sortOrder: orderListData
		})
		getEnrollments(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	function showFilters(field: string, fieldStyle: string) {
		const filterActual = fieldStyle + 'Filter'
		let classActualField = 'display-none'
		switch (filterActual) {
			case 'dateFilter':
			case 'numericFilter':
			case 'statusFilter':
				classActualField = 'enrollment-' + fieldStyle + '-filter'
				break
			case 'textFilter':
				classActualField = 'display-block'
				break
		}
		const activeFilter =
			filterActual === 'textFilter'
				? textFilter
				: filterActual === 'statusFilter'
					? statusFilter
					: dateFilter
		if (activeFilter === 'display-none') {
			setTextHeader(styles.textHeaderListAdmin)
			setTextFilter('display-none')
			setStatusFilter('display-none')
			setDateFilter('display-none')

			switch (filterActual) {
				case 'dateFilter':
					setDateFilter(classActualField)
					break
				case 'statusFilter':
					setStatusFilter(classActualField)
					break
				case 'textFilter':
					setTextFilter(classActualField)
					break
			}

			setFilterValue('')
			setActualField(field)
			setInitDate(undefined)
			setFinalDate(undefined)
			setMaxRange(undefined)
			setMinRange(undefined)
		} else if (actualField === field) {
			setTextHeader(styles.onClickHeader)
			setFilterValue('')
			switch (filterActual) {
				case 'dateFilter':
					setDateFilter('display-none')
					break
				case 'statusFilter':
					setStatusFilter('display-none')
					break
				case 'textFilter':
					setTextFilter('display-none')
					break
			}
			deleteDateRange()
		} else {
			setFilterValue('')
			setActualField(field)
		}
	}

	function closeFilter() {
		setTextFilter('display-none')
		setStatusFilter('display-none')
		setDateFilter('display-none')
		setFilterValue(undefined)
		deleteDateRange()
	}

	function handleDateRangeFilterChange(evt, field: string) {
		const rangeActual = field + 'Range'
		const evtValue = evt.value
		const dateFunc = field === 'init' ? setInitDate : setFinalDate
		const rangeFunc = field === 'init' ? setInitRange : setFinalRange
		if (evt.value !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', actualField, undefined, {
					[rangeActual]: evt.value
				})
				dateFunc(evtValue)
				rangeFunc(evt.value)
				emptyFilterField()
			} else if (evtValue.length < 11) {
				const message = parseDateWLanguage(evtValue, language)
				if (message) {
					if (message.newValue.length > 9) {
						dateFunc(new Dayjs(message.newValue))
					}
					//@ts-ignore /* TODO: Fix type mismatch (still works) */
					dateFunc(message.newValue)
				}
				rangeFunc(undefined)
			}
		}
	}

	function showFilterContainerBar() {
		if (filterContainerBar) {
			const booleanNames = {
				review: Constants.ENROLLMENT_STATUS.REVIEW + '_ENROLLMENT',
				new: Constants.ENROLLMENT_STATUS.NEW + '_ENROLLMENT',
				approved: Constants.ENROLLMENT_STATUS.APPROVED + '_ENROLLMENT',
				rejected: Constants.ENROLLMENT_STATUS.REJECTED + '_ENROLLMENT'
			}
			return (
				<ActiveFiltersBar
					closeFilterContainerBar={closeFilterContainerBar}
					dataFiltersArray={dataFiltersArray}
					deleteFilterActive={deleteFilterActive}
					objectType={Constants.LISTS.ENROLLMENTS}
					booleanNames={booleanNames}
				/>
			)
		} else {
			return null
		}
	}

	const handleTextFilterChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.preventDefault()
		setFilterValue(evt.target.value)
	}

	const handleFilterKeyDown = (evt: React.ChangeEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(evt, callDataList, emptyFilterField, actualField, filterValue, '', '')
	}

	const selectionFilter = (status: string, position: any) => {
		if (filtersOfTypeStatus.status.numSelectOptions < 5) {
			callDataList('', '', actualField, status, {})
			fillStatusInputFilter.splice(position, 1)
		}
	}

	const emptyFilterField = () => {
		setFilterValue('')
		setInitDate(undefined)
		setFinalDate(undefined)
	}

	return (
		<div className={'tableInformationWhiteStyle margins-table'}>
			<div className={styles.headerMainTableUsersList}>
				{headerButtons &&
					Object.keys(headerButtons).map((key: string) => (
						<HeaderTable
							key={key}
							dataMainHeaderTable={headerButtons[parseInt(key)]}
							textHeader={textHeader}
							showFilters={showFilters}
						/>
					))}
			</div>
			<TagsFilter
				showTagsFilter={statusFilter}
				actualField={actualField}
				callDataList={callDataList}
				selectionFilter={selectionFilter}
				closeFilter={closeFilter}
				fillInputFilter={fillStatusInputFilter}
			/>
			<DateInputFilter
				showDateFilter={dateFilter}
				actualField={actualField}
				initDate={initDate}
				finalDate={finalDate}
				callDataList={callDataList}
				initReferenceText={initReferenceText}
				finReferenceText={finReferenceText}
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			<TextInputFilter
				showTextFilter={textFilter}
				actualField={actualField}
				valueFilter={filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			{showFilterContainerBar()}
			<div className={'tableInformationWhiteStyle'}>
				<div className={'tableInformation'}>
					<ResultNotFound showNotFound={resultNotFound} />
					{Object.keys(dataEnrollmentInvitations).map((key: string) => (
						<TableRow
							key={key}
							id={dataEnrollmentInvitations[key]['id']}
							status={dataEnrollmentInvitations[key]['status']['value']}
							created_at={dataEnrollmentInvitations[key]['created_at']}
							customer={dataEnrollmentInvitations[key]['customer']}
							supplier={dataEnrollmentInvitations[key]['supplier']}
							isCustomer={isCustomer}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
