import { allowDecimals, getCurrentUser, URLS } from '@netcurio-ui/common'
import {
	CommentSectionVariant,
	CommentsSection,
	Header,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	Severity,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RouterChildContext, useHistory } from 'react-router-dom'
import { BranchOffice, ErrorWrapper, NewConsumptionItem } from '../../types'
import { beforeUnloadListener } from '../../utilities/beforeUnloadListener'
import { connection } from '../../utilities/connection'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { formatAddress } from '../../utilities/formatAddress'
import HeaderInformation from './headerInformation'
import ItemsList from './itemsList'
import { CancelModal } from './Modals/CancelModal'
import { ErrorModal } from './Modals/ErrorModal'
import { RegisterConsumption } from './Modals/RegisterConsumption'
import { RemoveBranchOffice } from './Modals/RemoveBranchOffice'
import styles from './newConsumption.module.scss'
import * as queries from './queries'
import Stock from './stock/stock'

interface CommentSend {
	text?: string
}

interface NewConsumption {
	branch_office_id?: string
	storage_location?: string
	storage_location_id?: string
	comment?: CommentSend
	items?: Array<ItemToPush>
}

interface ItemToPush {
	code?: string
	position: number
	quantity: number
	net_value: number
	storage_location_id?: string
	storage_location?: string
	supplier?: string
}

export function NewConsumption() {
	const client: DefaultClient<NormalizedCacheObject> = useMemo(
		(): DefaultClient<NormalizedCacheObject> => connection(),
		[]
	)
	beforeUnloadListener('add')
	const history: RouterChildContext['router']['history'] = useHistory()
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const { t } = useTranslation()
	const [newConsumption, setNewConsumption] = useState<NewConsumption>({})
	const [hideMainView, setHideMainView] = useState<boolean>(false)
	const [consumptionItems, setConsumptionItems] = useState<Array<NewConsumptionItem>>([])
	const [notFilledMessageVisibility, setNotFilledMessageVisibility] = useState<boolean>(false)
	const [messageMissingStock, setMessageMissingStock] = useState<boolean>(false)
	const [selectedBranchOffice, setSelectedBranchOffice] = useState<BranchOffice | null>(null)
	const [deliveryAddress, setDeliveryAddress] = useState<string>('')
	const [branchOfficeChange, setBranchOfficeChange] = useState<boolean>(false)
	const [commentSend, setCommentSend] = useState<CommentSend>({})
	const [emptyBranchOffice, setEmptyBranchOffice] = useState<boolean>(false)
	const [showCancelNewConsumption, setShowCancelNewConsumption] = useState<boolean>(false)
	const [showRemoveBranchOffice, setShowRemoveBranchOffice] = useState<boolean>(false)
	const [showRegisterConsumption, setShowRegisterConsumption] = useState<boolean>(false)
	const [errorResponse, setErrorResponse] = useState<ErrorWrapper | undefined>(undefined)

	const registerNewConsumption = () => {
		setShowSpinner && setShowSpinner(true)
		const dataNewConsumption: NewConsumption = {
			branch_office_id: newConsumption.branch_office_id,
			comment: !commentSend.text ? undefined : commentSend
		}
		const itemsToSave: Array<ItemToPush> = []
		consumptionItems.forEach((item: NewConsumptionItem) => {
			const itemToPush: ItemToPush = {
				position: item.position,
				code: item.product.id,
				quantity: item.quantity,
				net_value: item.net_value,
				supplier: item.product.supplier.rfc
			}
			if (item.storage_location) {
				itemToPush.storage_location_id = item.storage_location_id
				itemToPush.storage_location = item.storage_location
			}
			itemsToSave.push(itemToPush)
		})
		dataNewConsumption.items = itemsToSave

		client
			.mutate({
				mutation: queries.CREATE_CONSUMPTION,
				variables: dataNewConsumption
			})
			.then((result) => {
				const consumptionId = result.data.createConsumption
				history.replace(
					URLS.CONSUMPTION_DETAIL +
						'?consumption=' +
						consumptionId +
						'&customer=' +
						getCurrentUser().company.rfc
				)
				setShowSpinner(false)
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				const relatedStockInfo = error?.graphQLErrors[0]?.relatedInfo ?? ''
				const errorConsumptionCode = error?.graphQLErrors[0]?.code ?? ''
				if (!expiredToken(errorCode)) {
					setErrorResponse({
						code: errorCode,
						message: error.message
					})
					if (relatedStockInfo && errorConsumptionCode === 'CONSUMPTION_VALIDATION') {
						const consumptionItemsCopy = JSON.parse(JSON.stringify(consumptionItems))
						if (consumptionItemsCopy) {
							for (const stockItem of relatedStockInfo) {
								for (const item of consumptionItemsCopy) {
									if (item.position === stockItem.position) {
										item.availability = stockItem.availableStock
										break
									}
								}
								setMessageMissingStock(true)
							}
							setConsumptionItems(consumptionItemsCopy)
						}
					}
				}
				setShowSpinner(false)
			})
	}

	function addNewComment(newComment: CommentSend) {
		setCommentSend(newComment)
	}

	function setBranchOffice(newSelectedBranchOffice: BranchOffice) {
		if (consumptionItems.length) {
			setShowRemoveBranchOffice(true)
		} else {
			const newConsumptionCopy: NewConsumption = newConsumption
			let newDeliveryAddress = ''
			if (newSelectedBranchOffice) {
				newConsumptionCopy.branch_office_id = newSelectedBranchOffice.id
				newDeliveryAddress = formatAddress(newSelectedBranchOffice)
			} else {
				newConsumptionCopy.branch_office_id = undefined
				if (!!consumptionItems.length) {
					setShowRemoveBranchOffice(true)
				}
			}
			setNewConsumption(newConsumptionCopy)
			setSelectedBranchOffice(newSelectedBranchOffice)
			setDeliveryAddress(newDeliveryAddress)
			setBranchOfficeChange(!newSelectedBranchOffice)
			setEmptyBranchOffice(!newConsumptionCopy.branch_office_id)
		}
	}

	function hideStock(newConsumptionItems: Array<NewConsumptionItem>) {
		setHideMainView(false)
		setConsumptionItems(newConsumptionItems)
	}

	function showStock() {
		if (newConsumption.branch_office_id) {
			setHideMainView(true)
			setEmptyBranchOffice(false)
		} else {
			setEmptyBranchOffice(true)
		}
	}

	function deleteItem(position: number) {
		const newConsumptionItems = [...consumptionItems]
		newConsumptionItems.splice(position, 1)
		newConsumptionItems.forEach((item, index) => {
			item.position = index + 1
		})
		setConsumptionItems(newConsumptionItems)
		setNotFilledMessageVisibility(false)
	}

	function quantityInputChange(evt: React.ChangeEvent<HTMLInputElement>, index: number) {
		const newConsumptionItems = structuredClone(consumptionItems)
		if (evt.target.value === '.') {
			evt.target.value = '0.'
		}

		if (
			allowDecimals(evt.target.value) &&
			parseFloat(evt.target.value) <= newConsumptionItems[index].availability
		) {
			newConsumptionItems[index].quantity = evt.target.value
			newConsumptionItems[index].net_value =
				parseFloat(evt.target.value) * (newConsumptionItems[index]?.product?.unit_price ?? 0)
			setConsumptionItems(newConsumptionItems)
			setNotFilledMessageVisibility(false)
		}
	}

	function validateConsumptionItems() {
		let invalidItems = false
		consumptionItems.forEach((item: NewConsumptionItem) => {
			if (item.quantity > item.availability) {
				invalidItems = true
			}
		})
		if (!invalidItems && consumptionItems.length) {
			setShowRegisterConsumption(true)
		}
	}

	function removeBranchOffice() {
		if (consumptionItems.length) {
			setShowRemoveBranchOffice(true)
		} else {
			setSelectedBranchOffice(null)
			setDeliveryAddress('')
			setNewConsumption({})
			setBranchOfficeChange(true)
		}
	}

	function openBackModal() {
		if (consumptionItems.length > 0 || hideMainView || selectedBranchOffice || commentSend?.text) {
			setShowCancelNewConsumption(true)
		} else {
			beforeUnloadListener('remove')
			history.goBack()
		}
	}

	return (
		<NetcurioGrid
			container
			display="grid"
			gridTemplateRows="5.5rem 1fr"
			width="100%"
			height="100%"
			minHeight="100vh"
		>
			<Header>
				<div>
					<NetcurioButton
						color="error"
						variant={'outlined'}
						onClick={openBackModal}
						size="small"
						endIcon={<NetcurioIcons.CancelOutlined />}
					>
						<span> {t('cancelText')} </span>
					</NetcurioButton>
				</div>
				<div></div>
			</Header>
			{hideMainView ? (
				<NetcurioGrid container>
					<NetcurioGrid item xs={12}>
						<Stock
							hideStock={hideStock}
							branchOffice={newConsumption.branch_office_id}
							branchOfficeChange={branchOfficeChange}
							showStock={hideMainView}
							consumptionItems={consumptionItems}
						/>
					</NetcurioGrid>
				</NetcurioGrid>
			) : (
				<NetcurioGrid container className={styles.containerNewConsumption}>
					<NetcurioGrid
						item
						display="grid"
						gridTemplateRows="0.15fr 1fr"
						xs={8}
						sm={8}
						md={9}
						lg={9}
						xl={10}
					>
						<HeaderInformation
							setBranchOffice={setBranchOffice}
							emptyBranchOffice={emptyBranchOffice}
							removeBranchOffice={removeBranchOffice}
							selectedBranchOffice={selectedBranchOffice}
							deliveryAddress={deliveryAddress}
						/>
						<ItemsList
							showStock={showStock}
							consumptionItems={consumptionItems}
							deleteItem={deleteItem}
							quantityInputChange={quantityInputChange}
							notFilledMessageVisibility={notFilledMessageVisibility}
							messageMissingStock={messageMissingStock}
						/>
					</NetcurioGrid>
					<NetcurioGrid item xs={4} sm={4} md={3} lg={3} xl={2} paddingLeft="1rem">
						<CommentsSection
							onChange={(comment) => addNewComment({ text: comment })}
							variant={CommentSectionVariant.Simple}
						/>
						<div className={styles.buttonContainer}>
							<NetcurioButton
								variant="outlined"
								color="secondary"
								onClick={validateConsumptionItems}
								disabled={!(consumptionItems?.length > 0)}
							>
								<span> {t('consumptionRegister')} </span>
							</NetcurioButton>
						</div>
					</NetcurioGrid>
				</NetcurioGrid>
			)}
			<CancelModal
				open={showCancelNewConsumption}
				onClose={() => setShowCancelNewConsumption(false)}
				onAccept={() => {
					setShowCancelNewConsumption(false)
					beforeUnloadListener('remove')
					history.goBack()
				}}
			/>
			<RemoveBranchOffice
				open={showRemoveBranchOffice}
				onClose={() => setShowRemoveBranchOffice(false)}
				onAccept={() => {
					setShowRemoveBranchOffice(false)
					setSelectedBranchOffice(null)
					setDeliveryAddress('')
					setNewConsumption({})
					setConsumptionItems([])
					setBranchOfficeChange(true)
				}}
			/>
			<RegisterConsumption
				open={showRegisterConsumption}
				onClose={() => setShowRegisterConsumption(false)}
				onAccept={() => {
					setShowRegisterConsumption(false)
					registerNewConsumption()
				}}
			/>
			<ErrorModal
				open={!!errorResponse?.code}
				onAccept={() => history.goBack()}
				title={t('showError')}
				bodyText={t('errorCodeText', { idCode: errorResponse?.code })}
				textButton={t('acceptTextButton')}
				headerTitleSeverity={Severity.Error}
			/>
		</NetcurioGrid>
	)
}
