import Constants from './constants'
import { refreshToken } from './connection'

export function expiredToken(errorCode) {
	if (errorCode === Constants.ERROR.INVALID_TOKEN) {
		refreshToken(Constants.ERROR.INVALID_TOKEN)
		return true
	}
	return false
}
