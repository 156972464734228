import { ShowRootElementsContext } from '@netcurio-ui/components'
import { useContext } from 'react'

export const useLoading = () => {
	const { showSpinner, setShowSpinner } = useContext(ShowRootElementsContext)

	const showLoadingSpinner = () => {
		setShowSpinner?.(true)
	}

	const hideLoadingSpinner = () => {
		setShowSpinner?.(false)
	}

	return {
		showSpinner,
		setShowSpinner,
		showLoadingSpinner,
		hideLoadingSpinner
	}
}
