import { createContext, useState } from 'react'

export interface ShowElementProps {
	showSpinner: boolean
	setShowSpinner?(newValue: boolean): void
}
export interface ProviderProps {
	children: JSX.Element | JSX.Element[]
}

export const ShowRootElementsContext = createContext<ShowElementProps>({ showSpinner: false })

export const ShowRootContextProvider = ({ children }: React.PropsWithChildren<ShowElementProps>) => {
	const [showSpinner, setShowSpinner] = useState<boolean>(false)
	return (
		<ShowRootElementsContext.Provider value={{ showSpinner, setShowSpinner }}>
			{children}
		</ShowRootElementsContext.Provider>
	)
}
