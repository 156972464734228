import { TypesDataTable } from '@netcurio-ui/common'
import {
	ActiveFiltersBar,
	BarLoader,
	DateInputFilter,
	ResultNotFound,
	ShowRootElementsContext,
	TextInputFilter
} from '@netcurio-ui/components'
import classNames from 'classnames'
import { Dayjs } from 'dayjs'
import React, { ChangeEvent, KeyboardEvent, ReactElement, useContext, useEffect, useState } from 'react'
import { HeaderTable } from '../../components/HeaderTable/HeaderTable'
import Constants from '../../utilities/constants'
import listHelper from '../../utilities/listHelper'
import { tableFillHeader } from '../../utilities/tableFillHeader'
import styles from './consumptionList.module.scss'
import { objectHeaderArray } from './tableParameterArray'
import { TableRow } from './tableRow'
import { TypesObjectRange, TypesPropsMainTable, TypesStyleMainTable } from './types'

export const ConsumptionTable = ({
	closeFilterContainerBar,
	dataConsumption,
	dataFiltersArray,
	deleteFilterActive,
	deleteRange,
	filterContainerBar,
	filteringConsumption,
	rowsActual,
	setActivePagination,
	totalRows,
	userRole,
	showBarLoader,
	setShowBarLoader
}: TypesPropsMainTable): ReactElement => {
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [fillFields, setFillFields] = useState<Array<tableFillHeader>>([])
	const [styleMainTable, setStyleMainTable] = useState<TypesStyleMainTable>({
		resultNotFound: false,
		mainTableHeight: undefined,
		mainContainerHeight: undefined,
		activeHeight: true
	})
	const [dataTable, setDataTable] = useState<TypesDataTable>({
		textFilter: 'display-none',
		dateFilter: 'display-none',
		actualField: 'created_at',
		initDate: undefined,
		finalDate: undefined,
		filterValue: undefined,
		initRange: undefined,
		finalRange: undefined,
		textHeader: styles.textheaderListConsump,
		borderVariant: 'filterContainerBarConsumption',
		initDateErrorText: '',
		finalDateErrorText: '',
		deleteRange: false
	})

	useEffect(() => {
		setShowBarLoader(false)
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: window.innerHeight - Constants.SIZE.MAIN_COMPONENTS_HEIGHT,
			mainContainerHeight: window.innerHeight - Constants.SIZE.HEADERS_HEIGHT
		}))
	}, [])

	useEffect(() => {
		setFillFields(objectHeaderArray(userRole))
	}, [userRole])

	useEffect(() => {
		if (totalRows && rowsActual) {
			window.document
				.getElementsByClassName('tableInformation')[0]
				.addEventListener('scroll', handlePagination)
		}
		return () => {
			const element: Element = window.document.getElementsByClassName('tableInformation')[0]
			element && element.removeEventListener('scroll', handlePagination)
		}
	}, [rowsActual, totalRows])

	useEffect(() => {
		if (dataTable.deleteRange && deleteRange && dataTable.actualField === 'created_at')
			setDataTable((prevState) => ({
				...prevState,
				deleteRange: false,
				initRange: undefined,
				finalRange: undefined
			}))
		if (filterContainerBar && styleMainTable.activeHeight)
			setStyleMainTable((prevState) => ({
				...prevState,
				activeHeight: false,
				mainTableHeight: prevState.mainTableHeight - 40,
				mainContainerHeight: prevState.mainContainerHeight - 40
			}))
		else if (!filterContainerBar && !styleMainTable.activeHeight)
			setStyleMainTable((prevState) => ({
				...prevState,
				activeHeight: true,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
		setStyleMainTable((prevState) => ({
			...prevState,
			resultNotFound: totalRows < 1
		}))
		listHelper.updateHeaderBottomRadius(styles.tableHeaderClicked)
	}, [deleteRange, filterContainerBar, totalRows, deleteRange, dataTable])

	const showFilters = (field: string, fieldStyle: string): void => {
		const filterActual: string = fieldStyle + 'Filter'
		let classActualFieldDateFilter = 'display-none'
		let classActualFieldTextFilter = 'display-none'
		let activeFilter = ''
		switch (filterActual) {
			case 'dateFilter':
				classActualFieldDateFilter = `consumption-${fieldStyle}-filter`
				activeFilter = dataTable.dateFilter
				break
			case 'textFilter':
				classActualFieldTextFilter = 'display-block'
				activeFilter = dataTable.textFilter
				break
		}
		if (activeFilter === 'display-none') {
			const verifyMainContainer: boolean =
				styleMainTable.mainTableHeight >
				window.innerHeight - (dataTable.actualField === field || filterContainerBar ? 155 : 115)
			if (verifyMainContainer)
				setStyleMainTable((prevState) => ({
					...prevState,
					mainTableHeight: prevState.mainTableHeight - 40,
					mainContainerHeight: prevState.mainContainerHeight - 40
				}))

			setDataTable((prevState) => ({
				...prevState,
				initDate: undefined,
				finalDate: undefined,
				filterValue: '',
				initRange: undefined,
				finalRange: undefined,
				dateFilter: classActualFieldDateFilter,
				textFilter: classActualFieldTextFilter,
				actualField: field,
				initDateErrorText: '',
				finalDateErrorText: '',
				textHeader: styles.textheaderOnClick
			}))
		} else if (dataTable.actualField === field) {
			setStyleMainTable((prevState) => ({
				...prevState,
				mainTableHeight: prevState.mainTableHeight + 40,
				mainContainerHeight: prevState.mainContainerHeight + 40
			}))
			setDataTable((prevState) => ({
				...prevState,
				filterValue: '',
				textHeader: styles.textheaderListConsump,
				initRange: undefined,
				finalRange: undefined,
				[filterActual]: 'display-none'
			}))
		} else {
			setDataTable((prevState) => ({
				...prevState,
				actualField: field,
				filterValue: ''
			}))
		}
		listHelper.updateHeaderColors(fillFields, field, styles.tableHeaderClicked)
	}

	const callDataList = (
		sortField: string,
		order: string,
		fieldFilter: string,
		filterValue: string,
		objectRange: TypesObjectRange
	): void => {
		setShowSpinner && setShowSpinner(true)
		const initRange = objectRange?.initRange !== undefined ? objectRange.initRange : dataTable.initRange
		const finalRange =
			objectRange?.finalRange !== undefined ? objectRange.finalRange : dataTable.finalRange

		if (sortField !== '') {
			filteringConsumption(0, sortField, order, fieldFilter, undefined, undefined, undefined, undefined)
		} else {
			filteringConsumption(
				0,
				sortField,
				order,
				fieldFilter,
				filterValue,
				initRange,
				finalRange,
				undefined
			)
		}
		setDataTable((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const closeFilter = () => {
		setStyleMainTable((prevState) => ({
			...prevState,
			mainTableHeight: prevState.mainTableHeight + 40,
			mainContainerHeight: prevState.mainContainerHeight + 40
		}))
		setDataTable((prevState) => ({
			...prevState,
			dateFilter: 'display-none',
			textFilter: 'display-none',
			textHeader: styles.closeBackground,
			filterValue: undefined,
			initRange: undefined,
			finalRange: undefined
		}))
	}

	const handleDateRangeFilterChange = (evt: Dayjs, field: string) => {
		const evtValue: Dayjs = evt
		const dateActual: string = field + 'Date'
		const rangeActual: string = field + 'Range'
		if (evtValue !== null) {
			if (typeof evtValue === 'object') {
				callDataList('', '', dataTable.actualField, undefined, {
					[rangeActual]: evtValue
				})
				setDataTable((prevState) => ({
					...prevState,
					[dateActual]: evtValue,
					[rangeActual]: evtValue,
					[dateActual + 'StyleErrorText']: ''
				}))
				emptyFilterField()
			}
		}
	}

	const handleTextFilterChange = (event: ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target
		event.preventDefault()
		setDataTable((prevState) => ({
			...prevState,
			filterValue: value
		}))
	}

	const handleFilterKeyDown = (event: KeyboardEvent<HTMLInputElement> | string) => {
		listHelper.handleFilterKeyDown(
			typeof event === 'object' ? { ...event, target: event.target as HTMLInputElement } : event,
			callDataList,
			emptyFilterField,
			dataTable.actualField,
			dataTable.filterValue,
			'',
			''
		)
	}

	const emptyFilterField = (): void => {
		setDataTable((prevState) => ({
			...prevState,
			filterValue: '',
			finalDate: undefined,
			initDate: undefined
		}))
	}

	const handlePagination = () => {
		listHelper.handlePagination(
			'tableInformation',
			totalRows,
			rowsActual,
			undefined,
			setActivePagination,
			setShowBarLoader
		)
	}

	return (
		<div className={classNames(styles.tableConsumptionMainContainer)}>
			<div className={styles.headerMainTableConsumption}>
				{fillFields.map((itemHeader: tableFillHeader) => (
					<HeaderTable
						key={itemHeader.name}
						textHeader={dataTable.textHeader}
						showFilters={showFilters}
						dataMainHeaderTable={itemHeader}
					/>
				))}
			</div>
			<TextInputFilter
				showTextFilter={dataTable.textFilter}
				actualField={dataTable.actualField}
				valueFilter={dataTable.filterValue}
				callDataList={callDataList}
				closeFilter={closeFilter}
				handleTextFilterChange={handleTextFilterChange}
				handleFilterKeyDown={handleFilterKeyDown}
			/>
			<DateInputFilter
				showDateFilter={dataTable.dateFilter}
				actualField={dataTable.actualField}
				initDate={dataTable.initDate}
				finalDate={dataTable.finalDate}
				callDataList={callDataList}
				initReferenceText="init"
				finReferenceText="final"
				closeFilter={closeFilter}
				handleDateRangeFilterChange={handleDateRangeFilterChange}
			/>
			{filterContainerBar && (
				<ActiveFiltersBar
					objectType={Constants.LISTS.CONSUMPTIONS}
					dataFiltersArray={dataFiltersArray}
					closeFilterContainerBar={closeFilterContainerBar}
					deleteFilterActive={deleteFilterActive}
				/>
			)}
			<div
				className="tableInformationWhiteStyle"
				style={{ height: styleMainTable.mainContainerHeight }}
			>
				<div className="tableInformation" style={{ height: styleMainTable.mainTableHeight }}>
					<ResultNotFound showNotFound={styleMainTable.resultNotFound} />
					{Object.keys(dataConsumption).map((key: string) => (
						<TableRow
							key={key}
							dataConsumption={dataConsumption[parseInt(key)]}
							userRole={userRole}
						/>
					))}
				</div>
				<BarLoader idBarLoader={'barSpinner'} showLoader={showBarLoader} />
			</div>
		</div>
	)
}
