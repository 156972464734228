import { dateFormatter, PURCHASE, Status } from '@netcurio-ui/common'
import {
	NetcurioGrid,
	NetcurioTableCell,
	NetcurioTableRow,
	NetcurioTooltip,
	PricesInformation
} from '@netcurio-ui/components'
import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PurchaseOrderItem } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import Formatter from '../../../../../utilities/formatter'
import styles from '../../purchaseOrderDetail.module.scss'

interface TableRowProps {
	statusPO: Status
	typePO: Status
	informationTable: PurchaseOrderItem
}

export const TableRow: FC<TableRowProps> = ({
	statusPO,
	typePO,
	informationTable: {
		position,
		name,
		net_value,
		tax,
		reference_price,
		unit_price,
		price_change_reason,
		storage_location,
		requested_amount,
		unit,
		confirmed_amount,
		rejected_reason,
		delivered_quantity,
		required_delivery_date,
		delivery_proposal_date,
		code
	}
}) => {
	const { t } = useTranslation()
	const [proposalDate, setProposalDate] = useState<Dayjs | string>()

	useEffect(() => {
		getProposalDate()
	}, [])

	const getConfirmedAmount = () => {
		if (statusPO.key === Constants.STATUS.NEW) {
			return t(PURCHASE.PENDING)
		}
		if (!confirmed_amount) {
			return t('NAtext')
		}
		return `${confirmed_amount} ${unit}`
	}

	const getDeliveredQuantity = () => {
		if (confirmed_amount === null && !delivered_quantity) {
			return t('NAtext')
		}
		if (delivered_quantity === 0) {
			return t(PURCHASE.PENDING)
		}
		return `${delivered_quantity} ${unit}`
	}

	const getProposalDate = () => {
		if (statusPO.key === Constants.STATUS.NEW) {
			setProposalDate(t(PURCHASE.PENDING))
		} else if (
			!delivery_proposal_date &&
			statusPO.key !== Constants.STATUS.NEW &&
			statusPO.key !== Constants.STATUS.CONFIRMED
		) {
			setProposalDate(t('NAtext'))
		} else {
			setProposalDate(dayjs(delivery_proposal_date).format('DD/MM/YYYY'))
		}
	}

	return (
		<>
			<NetcurioTableRow isDetailRow>
				<NetcurioTableCell align="center">
					<p className={styles.margin0}>{position}</p>
				</NetcurioTableCell>
				<NetcurioTableCell>
					<NetcurioTooltip title={name} placement="right">
						<p className={styles.descriptionMargin}>{`${t('itemDescription')} ${name}`}</p>
					</NetcurioTooltip>
					<p className={styles.margin0}>{`${t('itemCode')} ${code}`}</p>
					{typePO?.key !== PURCHASE.PURCHASE_ORDER_TYPES.CONSIGNMENT && (
						<div>
							<p className={styles.margin0}>{`${t('itemNetValue')} ${Formatter.currency.format(
								net_value
							)}`}</p>
							<PricesInformation
								referencePrice={reference_price}
								unitPrice={unit_price}
								priceChangeReason={price_change_reason}
							/>
							<p className={styles.margin0}>{`${t('ivaText')} ${Formatter.percent.format(
								tax
							)}`}</p>
						</div>
					)}
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p className={styles.margin0}>{storage_location ?? t('NAtext')}</p>
				</NetcurioTableCell>
				<NetcurioTableCell>
					<NetcurioGrid
						minHeight="9rem"
						display="flex"
						flexDirection="column"
						justifyContent="space-around"
					>
						<p className={styles.margin0}>{`${t(
							'requestedLabel'
						)} ${requested_amount} ${unit}`}</p>
						<p className={styles.margin0}>{`${t('confirmedLabel')} ${getConfirmedAmount()}`}</p>
						{statusPO.key !== Constants.STATUS.NEW &&
							((!confirmed_amount && statusPO.key !== Constants.STATUS.CONFIRMED) ||
								confirmed_amount !== requested_amount) && (
								<p
									className={classNames(styles.margin0, {
										[styles.errorText]: rejected_reason?.value
									})}
								>
									{rejected_reason?.value}
								</p>
							)}
						<p className={styles.margin0}>{`${t('deliveredLabel')} ${getDeliveredQuantity()}`}</p>
					</NetcurioGrid>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p className={styles.margin0}>
						{required_delivery_date
							? required_delivery_date
								? dateFormatter.format(new Date(required_delivery_date.toString()))
								: ''
							: t('NAtext')}
					</p>
				</NetcurioTableCell>
				<NetcurioTableCell align="center">
					<p className={classNames(styles.margin0, { [styles.errorText]: proposalDate === 'N/A' })}>
						{proposalDate}
					</p>
				</NetcurioTableCell>
			</NetcurioTableRow>
		</>
	)
}
