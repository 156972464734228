import {
	Comment,
	Roles,
	URLS,
	dateFormatter,
	getCurrentUser,
	getSerieFolio,
	parseTimestampToTimeZone
} from '@netcurio-ui/common'
import {
	CommentSectionVariant,
	CommentsSection,
	FileViewerModal,
	Header,
	NetcurioButton,
	NetcurioGrid,
	NetcurioIcons,
	ShowRootElementsContext,
	StatusInformation,
	TrackInformation,
	accountedStatus,
	halfPaymentComplement,
	statusLoading,
	voidedStatus
} from '@netcurio-ui/components'
import { useQuery } from '@tanstack/react-query'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { fetchEnvironment } from '../../api/fetch-environment'
import '../../style.scss'
import { FileViewerObject, Payment, PaymentComplement } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadXMLFile } from '../../utilities/file-handling/download-xml-file'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import UserInformation from '../../utilities/userInformation'
import MainTable from './mainTable'
import { ErrorModal } from './Modals/ErrorModal/ErrorModal'
import { RejectPendingMessagePaymentComplement } from './Modals/RejectPendingMessagePaymentComplement/RejectPendingMessagePaymentComplement'
import { VoidPaymentComplementModal } from './Modals/VoidPaymentComplementModal/VoidPaymentComplementModal'
import * as queries from './queries'
import styles from './styles.module.scss'
import { ITrackInformationData } from './types'

export const PaymentComplementDetail = (): ReactElement => {
	const { data: environment } = useQuery({
		queryKey: ['environment'],
		queryFn: fetchEnvironment,
		suspense: true,
		staleTime: Infinity
	})
	const { S3_BUCKET } = environment ? environment : { S3_BUCKET: '' }

	const { t } = useTranslation()
	const history = useHistory()
	const currentUser = useMemo(() => getCurrentUser(), [])
	const client: DefaultClient<NormalizedCacheObject> = useMemo(
		(): DefaultClient<NormalizedCacheObject> => connection(),
		[]
	)
	const [paymentComplement, setPaymentComplement] = useState<PaymentComplement>({
		uuid: getUrlParameter('paymentComplement'),
		status: {
			key: '',
			value: ''
		},
		void_reason: '',
		sender: { name: '', rfc: '' },
		receiver: { name: '', rfc: '' },
		uploaded_by: { name: '', lastname: '', email: '' },
		created_at: 0,
		payments: [],
		comments: []
	})
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [informationTable, setInformationTable] = useState<Payment[]>([])
	const [informationComments, setInformationComments] = useState<Comment[]>([])
	const [showVoidButton, setShowVoidButton] = useState<boolean>(false)
	const [goToList, setGoToList] = useState<boolean>(false)
	const [mainTableHeight, setMainTableHeight] = useState<number>(100)
	const [errorModal, setErrorModal] = useState<boolean>(false)
	const [errorCode, setErrorCode] = useState<string>('')
	const [role, setRole] = useState<string>('')
	const [existMessagePending, setExistMessagePending] = useState<boolean>(false)
	const [fileViewer, setFileViewer] = useState<FileViewerObject>({
		open: false,
		titleText: '',
		fileSrc: ''
	})
	const [opendVoidPaymentComplement, setOpendVoidPaymentComplement] = useState<boolean>(false)
	const [openPendingMessageModal, setOpenPendingMessageModal] = useState<boolean>(false)

	useEffect(() => {
		setShowSpinner && setShowSpinner(true)
		client
			.query({
				query: queries.PAYMENT_COMPLEMENT_DETAILS,
				variables: {
					uuid: paymentComplement.uuid
				}
			})
			.then((result) => {
				if (result.data.PaymentComplement) {
					setPaymentComplement(result.data.PaymentComplement)
					setInformationTable(result.data.PaymentComplement.payments)
					setMainTableHeight(result.data.PaymentComplement.payments.length * 40 + 150)
					setInformationComments(result.data.PaymentComplement.comments)
					if (result.data.PaymentComplement.status.key !== 'VOIDED') {
						setShowVoidButton(true)
					} else {
						setShowVoidButton(false)
					}
				} else {
					const errorCode = showErrorComponent(undefined)
					setErrorModal(true)
					setErrorCode(errorCode)
				}
				setShowSpinner(false)
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setErrorModal(true)
					setErrorCode(errorCode)
				}
				setShowSpinner(false)
			})
		if (currentUser) {
			const role = UserInformation.getCompanyRole()
			setRole(role)
		}
	}, [])

	useEffect(() => {
		verifyPendingMessage()
	}, [errorModal, goToList])

	useEffect(() => {
		if (paymentComplement) {
			const { uuid, serie, folio } = paymentComplement
			const fileName = `${uuid.toLowerCase()}.pdf`
			const fileSrc = `${S3_BUCKET}${fileName}`
			const serieFolio = getSerieFolio(serie, folio)
			const titleText = `${t('paymentComplementText')} ${serieFolio}`
			setFileViewer((state) => ({ ...state, titleText, fileSrc }))
		}
	}, [paymentComplement, getSerieFolio, t, S3_BUCKET])

	const voidPaymentComplement = async (reason) => {
		setShowSpinner && setShowSpinner(true)
		await client
			.mutate({
				mutation: queries.VOID_PAYMENT_COMPLEMENT,
				variables: {
					uuid: paymentComplement.uuid,
					reason: reason
				}
			})
			.then(() => {
				setOpendVoidPaymentComplement(false)
				document.location.reload()
			})
			.catch((error) => {
				console.error('Error', error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setErrorModal(true)
					setErrorCode(errorCode)
				}
			})
			.finally(() => {
				setShowSpinner(false)
			})
	}

	const cancelButtonAction = () => {
		setGoToList(true)
	}
	const addNewComment = (newCommentData) => {
		const dataNewComment = {
			payment_complement: paymentComplement.uuid,
			text: newCommentData.text
		}
		client
			.mutate({
				mutation: queries.CREATE_PAYMENT_COMPLEMENT_COMMENT,
				variables: dataNewComment
			})
			.then(() => {
				client
					.query({
						query: queries.PAYMENT_COMPLEMENT_COMMENTS,
						variables: {
							uuid: paymentComplement.uuid
						},
						fetchPolicy: 'no-cache'
					})
					.then((result) => {
						setInformationComments(result.data.PaymentComplement.comments)
						setExistMessagePending(false)
					})
					.catch((error) => {
						console.error(error)
						const errorCode = showErrorComponent(error)
						if (!expiredToken(errorCode)) {
							setErrorModal(true)
							setErrorCode(errorCode)
						}
					})
			})
			.catch((error) => {
				console.error(error)
				const errorCode = showErrorComponent(error)
				if (!expiredToken(errorCode)) {
					setErrorModal(true)
					setErrorCode(errorCode)
				}
			})
	}

	const pendingMessageHandler = (booleanPendingMessage: string) => {
		if (existMessagePending !== !!booleanPendingMessage) {
			setExistMessagePending(!!booleanPendingMessage)
		}
	}
	const getButtonVoid = () => {
		if (role === Roles.SUPPLIER && showVoidButton) {
			return (
				<div className={styles.buttonVoidedContainer}>
					<NetcurioButton
						variant="text"
						color="secondary"
						onClick={() => setOpendVoidPaymentComplement(true)}
						endIcon={<NetcurioIcons.Close />}
					>
						<span>{t('voidPaymentComplement')}</span>
					</NetcurioButton>
				</div>
			)
		}
	}
	const mapStatuses = useCallback((): StatusInformation[] => {
		const statuses = {
			[Constants.PAYMENT_COMPLEMENT_STATUS.ACCOUNTED]: [accountedStatus('', 'MALE')],
			[Constants.PAYMENT_COMPLEMENT_STATUS.VOIDED]: [voidedStatus('', 'MALE')],
			[Constants.PAYMENT_COMPLEMENT_STATUS.HALF]: [halfPaymentComplement()],
			DEFAULT: [statusLoading()]
		}
		return statuses[paymentComplement?.status.key] || statuses['DEFAULT']
	}, [paymentComplement])

	const mapTrackInformationData = useCallback((): ITrackInformationData => {
		const trackInformationData = {
			sender: paymentComplement.sender.name,
			rfcSender: paymentComplement.sender.rfc,
			UUID: paymentComplement.uuid,
			date_pc: dateFormatter.format(parseTimestampToTimeZone(paymentComplement?.date)),
			receiver: paymentComplement.receiver.name,
			rfcReceiver: paymentComplement.receiver.rfc,
			uploadedBy: paymentComplement.uploaded_by.name + ' ' + paymentComplement.uploaded_by.lastname,
			uploadedAt: dateFormatter.format(parseTimestampToTimeZone(paymentComplement?.created_at))
		}
		if (role === Roles.CUSTOMER) {
			delete trackInformationData.uploadedBy
		}
		return trackInformationData
	}, [paymentComplement])

	const closePDFModal = () => setFileViewer((state) => ({ ...state, open: false }))

	const verifyPendingMessage = () => {
		if (goToList) {
			if (existMessagePending) {
				setOpenPendingMessageModal(true)
			} else {
				history.push(URLS.COMPLEMENT_LIST)
			}
		}
	}

	const closePendingMessageModal = () => {
		setOpenPendingMessageModal(false)
		setGoToList(false)
	}
	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start">
			<NetcurioGrid item alignItems="center" xs={12}>
				<Header>
					<div>
						<NetcurioButton
							variant="outlined"
							color="error"
							size={'small'}
							onClick={cancelButtonAction}
							endIcon={<NetcurioIcons.ArrowBack />}
						>
							<span> {t('comebackListText')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							size={'small'}
							endIcon={<NetcurioIcons.Download />}
							onClick={() => {
								downloadXMLFile(S3_BUCKET, paymentComplement.uuid)
							}}
						>
							<span> {t('downloadText')} </span>
						</NetcurioButton>
						<NetcurioButton
							variant="outlined"
							size={'small'}
							onClick={() => setFileViewer((state) => ({ ...state, open: true }))}
							endIcon={<NetcurioIcons.Visibility />}
						>
							<span> {t('seePaymentComplement')} </span>
						</NetcurioButton>
					</div>
					<div></div>
				</Header>
			</NetcurioGrid>
			<div className={styles.containerTrackTotalMiddle}>
				<div className={styles.mainComplementContainer}>
					<TrackInformation
						id={getSerieFolio(paymentComplement?.serie, paymentComplement?.folio)}
						information={mapTrackInformationData()}
						title="paymentComplement"
						gridTemplateAreas={
							role === Roles.CUSTOMER
								? `'sender rfcSender UUID date_pc'
						'receiver rfcReceiver uploadedAt uploadedBy'`
								: `'sender rfcSender UUID date_pc'
							'receiver rfcReceiver uploadedBy uploadedAt'`
						}
						headerMessage={paymentComplement.void_reason}
						showHeaderMessage={!!paymentComplement.void_reason}
						statuses={mapStatuses()}
					/>
					<div className={styles.separatorGray}></div>
					<MainTable mainTableHeight={mainTableHeight} informationTable={informationTable} />
				</div>
				<div className={styles.lateralColumnContainerPC}>
					<CommentsSection
						variant={CommentSectionVariant.WithBody}
						comments={informationComments}
						onAddComment={addNewComment}
						onChange={pendingMessageHandler}
					/>
					{getButtonVoid()}
				</div>
			</div>
			<FileViewerModal onClose={closePDFModal} {...fileViewer} />
			<VoidPaymentComplementModal
				open={opendVoidPaymentComplement}
				close={() => setOpendVoidPaymentComplement(false)}
				voidPayment={(reason) => voidPaymentComplement(reason)}
			/>
			<RejectPendingMessagePaymentComplement
				open={openPendingMessageModal}
				close={() => closePendingMessageModal()}
				onAccept={() => history.push(URLS.COMPLEMENT_LIST)}
			/>
			<ErrorModal open={!!errorCode} errorCode={errorCode} />
		</NetcurioGrid>
	)
}
