import { Comment, Roles, URLS } from '@netcurio-ui/common'
import {
	CommentSectionSize,
	CommentsSection,
	Header,
	NetcurioButton,
	NetcurioIcons,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import { CommentSectionVariant } from '@netcurio-ui/components/src'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import classNames from 'classnames'
import dayjs from 'dayjs'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { CancelButton } from '../../components/HeaderButtons/CancelButton'
import { Product } from '../../types'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import Formatter from '../../utilities/formatter'
import UserInformation from '../../utilities/userInformation'
import { ViewAllModalsRFQ } from './ModalsRFQ/ViewAllModalsRFQ'
import { NewQuotation } from './NewQuotation/NewQuotation'
import { QuotationDetail } from './QuotationModule/QuotationDetail'
import { QuotationsList } from './QuotationModule/QuotationsList'
import styles from './requestForQuotation.module.scss'
import { ButtonNewQuotation } from './RFQDetail/ButtonNewQuotation'
import { ButtonsRFQDetail } from './RFQDetail/ButtonsRFQDetail'
import { MainTableRFQDetail } from './RFQDetail/MainTableRFQDetail'
import { TrackInformation } from './RFQDetail/TrackInformation'
import { TrackInformationHeader } from './RFQDetail/TrackInformationHeader'
import {
	FreightAndTerms,
	InformationRFQItem,
	ItemQ,
	ItemQuotation,
	NewQuotation as NewQuotationType,
	QuotationData,
	QuotationInfo,
	RequestForQuotation,
	StateRequestForQuotationDetail
} from './types'
import { QuotationItem } from './types/QuotationItem'
import { dataRFQDefault, defaultItemRFQ, informationDataRFQDefault } from './utilities/defaultValues'
import {
	checkCurrencyList,
	createDefaultItemQuotation,
	createItemsQuotationToSave,
	generateItemsFreightAndTerms,
	getFullInformationProduct,
	getNetTotalQuotation,
	setCurrency
} from './utilities/helpersRFQ'
import {
	CREATE_QUOTATION,
	CREATE_RFQ_COMMENT,
	CURRENCIES_CATALOGUE,
	FREIGHT_CATALOGUE,
	PAYMENT_TERMS,
	QUOTATION,
	REQUEST_FOR_QUOTATION_COMMENTS,
	REQUEST_FOR_QUOTATION_DETAIL
} from './utilities/queries'

export const RequestForQuotationDetail: FC = () => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const history = useHistory<string>()
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [errorCodeModal, setErrorCodeModal] = useState<string>()
	const [dataRFQ, setDataRFQ] = useState<StateRequestForQuotationDetail>(dataRFQDefault)
	const [informationRFQ, setInformationRFQ] = useState<RequestForQuotation>(informationDataRFQDefault)
	const [informationRFQItems, setInformationRFQItems] = useState<Array<InformationRFQItem>>([])
	const [informationListQuotations, setInformationListQuotations] = useState<Array<QuotationData>>([])
	const [informationComments, setInformationComments] = useState<Array<Comment>>([])
	const [newQuotation, setNewQuotation] = useState<NewQuotationType>()
	const [settingValidateCatalogue, setSettingValidateCatalogue] = useState<boolean>()
	const [allPaymentTerms, setAllPaymentTerms] = useState<Array<FreightAndTerms>>([])
	const [allFreights, setAllFreights] = useState<Array<FreightAndTerms>>([])
	const [currencies, setCurrencies] = useState<Array<FreightAndTerms>>()
	const [quotationInfo, setQuotationInfo] = useState<QuotationInfo>()
	const [commentPending, setCommentPending] = useState<{ text: string }>()
	const [expiredDate, setExpiredDate] = useState<dayjs.Dayjs>(null)
	const [fullInformationProducts, setFullInformationProducts] = useState<Array<Product>>([])
	const [commentsSectionVariant, setCommentsSectionVariant] = useState<CommentSectionVariant>(
		CommentSectionVariant.ReadOnly
	)

	const [resetData, setResetData] = useState<{
		freight: FreightAndTerms
		paymentTerms: FreightAndTerms
	}>()
	const [userRole, setUserRole] = useState<string | undefined>('')
	const [hasPdfFile, setHasPdfFile] = useState(false)
	const { t } = useTranslation()

	useEffect(() => {
		getRFQData()
		getUserRole()
	}, [])

	useEffect(() => {
		if (expiredDate || newQuotation?.currency) {
			activateNewQuotationButton()
		}
	}, [expiredDate, newQuotation?.currency])

	useEffect(() => {
		if (!dataRFQ.showNewQuotation) {
			setDataRFQ((prevState) => ({
				...prevState,
				cleanReasons: true
			}))
		}
		if (informationRFQItems.length > 0 && settingValidateCatalogue) {
			getFullInformationProduct(informationRFQItems, setFullInformationProducts, handleError)
		}
	}, [dataRFQ.showNewQuotation, informationRFQItems])

	const getUserRole = () => {
		setUserRole(UserInformation.getCompanyRole())
	}

	const getRFQData = () => {
		setShowSpinner?.(true)
		client
			.query({
				query: REQUEST_FOR_QUOTATION_DETAIL,
				variables: {
					id: searchParams.get('rfq'),
					customer: searchParams.get('customer')
				},
				fetchPolicy: 'no-cache'
			})
			.then(async (result: ApolloQueryResult<{ RequestForQuotation: RequestForQuotation }>) => {
				const data: RequestForQuotation = result.data.RequestForQuotation
				if (data) {
					const arrayItemsRFQ: Array<ItemQuotation> = []
					if (data.items) {
						data.items.forEach((): void => {
							arrayItemsRFQ.push(structuredClone(defaultItemRFQ))
						})
					}
					setInformationRFQ(data)
					setInformationRFQItems(data.items)
					setInformationListQuotations(data.quotations)
					setInformationComments(data.comments)
					setNewQuotation({
						itemsQuotation: arrayItemsRFQ,
						netTotal: 0,
						currency: '',
						freight: data.incoterm,
						paymentTerms: data.payment_term
					})
					setResetData({
						freight: data.incoterm,
						paymentTerms: data.payment_term
					})
					setSettingValidateCatalogue(data.setting_validate_catalogue)
					const statusText: string = data.status?.key ?? ''
					setCommentsSectionVariant(
						statusText === Constants.QUOTATION.CLOSED
							? CommentSectionVariant.ReadOnly
							: CommentSectionVariant.WithBody
					)
					if (data.attachment) {
						setHasPdfFile(true)
					}
					if (searchParams.get('quotation') && userRole === Roles.CUSTOMER) {
						const id: string = searchParams.get('quotation')
						getQuotation(id, data.supplier.rfc)
					}
					if (window.sessionStorage.getItem('rejectRFQ') === 'true') {
						window.sessionStorage.removeItem('rejectRFQ')
						setDataRFQ((prevState) => ({
							...prevState,
							showClosedRFQMessage: true
						}))
					} else if (window.sessionStorage.getItem('closeRFQ') === 'true') {
						window.sessionStorage.removeItem('closeRFQ')
						setDataRFQ((prevState) => ({
							...prevState,
							showClosedRFQMessage: true
						}))
					}
					if (!data.setting_validate_catalogue) {
						await searchFreight('')
						await searchPaymentTerms('', data.customer.rfc)
						await searchCurrencies('')
						const event: { value: FreightAndTerms } = { value: { code: 'MXN', description: '' } }
						selectedCurrency(event)
					}
				} else {
					setErrorCodeModal(showErrorComponent(undefined))
				}
				setShowSpinner?.(false)
			})
			.catch(handleError)
	}

	const resetDataPage = () => {
		setDataRFQ(dataRFQDefault)
		setInformationListQuotations([])
		setInformationRFQ(informationDataRFQDefault)
		setExpiredDate(null)
		setCommentPending({
			text: undefined
		})
		getRFQData()
	}
	const searchFreight = (searchText: string) => {
		client
			.query({
				query: FREIGHT_CATALOGUE,
				variables: {
					search_text: searchText.toLowerCase()
				}
			})
			.then((result: ApolloQueryResult<{ IncotermsByDescriptionOrCode: Array<FreightAndTerms> }>) => {
				if (result.data.IncotermsByDescriptionOrCode) {
					setAllFreights(
						result.data.IncotermsByDescriptionOrCode.map((item: FreightAndTerms) =>
							generateItemsFreightAndTerms(item)
						)
					)
				}
			})
			.catch(handleError)
	}

	const searchPaymentTerms = (searchText: string, customer?: string) => {
		if (customer || informationRFQ.customer?.rfc) {
			client
				.query({
					query: PAYMENT_TERMS,
					variables: {
						search_text: searchText,
						customer: customer ? customer : informationRFQ.customer?.rfc
					}
				})
				.then(
					(
						result: ApolloQueryResult<{ PaymentTermsByDescriptionOrCode: Array<FreightAndTerms> }>
					) => {
						if (result.data.PaymentTermsByDescriptionOrCode) {
							setAllPaymentTerms(
								result.data.PaymentTermsByDescriptionOrCode.map((item: FreightAndTerms) =>
									generateItemsFreightAndTerms(item)
								)
							)
						}
					}
				)
				.catch(handleError)
		}
	}

	const searchCurrencies = async (query: string): Promise<void> => {
		client
			.query({
				query: CURRENCIES_CATALOGUE
			})
			.then((result: ApolloQueryResult<{ Currencies: Array<FreightAndTerms> }>) => {
				if (result.data.Currencies) {
					let temp: Array<FreightAndTerms> = result.data.Currencies.map((item: FreightAndTerms) =>
						generateItemsFreightAndTerms(item)
					)
					if (query) {
						temp = temp.filter(
							(item: FreightAndTerms): boolean => item.code === query.toUpperCase()
						)
					}
					setCurrencies(temp)
				}
			})
			.catch(handleError)
	}

	const selectedCurrency = (event?: { value: FreightAndTerms }) => {
		if (event) {
			const value: string = event?.value?.code
			setNewQuotation((prevState) => ({
				...prevState,
				currency: value
			}))
			setDataRFQ((prevState) => ({
				...prevState,
				currencyQuotationRequired: false
			}))
		} else {
			setNewQuotation((prevState) => ({
				...prevState,
				currency: ''
			}))
			setDataRFQ((prevState) => ({
				...prevState,
				currencyQuotationRequired: true
			}))
		}
	}

	const getQuotation = (id: string, supplier: string) => {
		setShowSpinner?.(true)
		client
			.query({
				query: QUOTATION,
				variables: {
					id,
					supplier
				}
			})
			.then((result: ApolloQueryResult<{ Quotation: QuotationInfo }>) => {
				if (result.data.Quotation) {
					setQuotationInfo(result.data.Quotation)
					setDataRFQ((prevState) => ({
						...prevState,
						showMainTableRFQ: false,
						showTrackInformation: false,
						showQuotationDetail: true
					}))
				}
				setShowSpinner?.(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		const errorCode = showErrorComponent(error)
		if (errorCode === Constants.QUOTATION.RFQ_CLOSED) {
			setDataRFQ((prevState) => ({
				...prevState,
				quotationClosedForClient: true
			}))
		}
		if (!expiredToken(errorCode)) {
			setErrorCodeModal(errorCode)
		}
		setShowSpinner?.(false)
	}
	const actionButton = (button: string) => {
		switch (button) {
			case 'comeBack':
				if (dataRFQ.createQuotationOpen) {
					setDataRFQ((prevState) => ({
						...prevState,
						cancelQuotationModal: true,
						goToList: true
					}))
				} else if (dataRFQ.existMessagePending) {
					setDataRFQ((prevState) => ({
						...prevState,
						pendingCommentModal: true
					}))
				} else {
					history.push(URLS.RFQ)
				}
				break
			case 'closeRFQ':
				setDataRFQ((prevState) => ({
					...prevState,
					closeRFQModal: true
				}))
				break
			case 'rejectRFQ':
				setDataRFQ((prevState) => ({
					...prevState,
					rejectRFQModal: true
				}))
				break
			case 'downloadPdfPo':
				setDataRFQ((prevState) => ({
					...prevState,
					openPDFModal: true
				}))
				break
			default:
				break
		}
	}

	const hideQuotationDetailComponent = () => {
		setDataRFQ((prevState) => ({
			...prevState,
			showTrackInformation: true,
			showMainTableRFQ: true,
			showQuotationDetail: false,
			showButtonsNewQuotation: false
		}))
	}

	const closeNewQuotation = () => {
		setDataRFQ((prevState) => ({
			...prevState,
			cancelQuotationModal: true,
			createQuotationOpen: false
		}))
	}

	const setUpdateNewQuotation = (newQuotationToUpdate: NewQuotationType) => {
		if (!settingValidateCatalogue) {
			setNewQuotation(newQuotationToUpdate)
		} else {
			newQuotationToUpdate.currency = setCurrency(newQuotation)
			setNewQuotation(newQuotationToUpdate)
		}
		getNetTotalQuotation(newQuotation, setNewQuotation)
	}

	const activateNewQuotationButton = () => {
		const items: Array<ItemQuotation> = newQuotation.itemsQuotation
		let newQuotationValid = true
		const itemsValid: boolean = items.every((item: ItemQuotation) => {
			if (item.rejectReason) {
				return true
			}
			return item.itemsQ.every((itemQ: ItemQ) => {
				return (
					itemQ.product &&
					itemQ.amount !== '' &&
					parseFloat(itemQ.amount as string) !== 0 &&
					itemQ.requiredDate &&
					itemQ.unitPrice !== 'unitPrice' &&
					itemQ.unitPrice !== '' &&
					itemQ.tax !== 'taxIVA' &&
					parseFloat(itemQ.unitPrice as string) !== 0
				)
			})
		})
		if (settingValidateCatalogue) {
			newQuotationValid = itemsValid && !!expiredDate
		} else {
			newQuotationValid =
				newQuotation.freight &&
				newQuotation.paymentTerms &&
				newQuotation.currency !== '' &&
				itemsValid &&
				!!expiredDate
		}
		setDataRFQ((prevState) => ({
			...prevState,
			disabledNewQuotationButton: !newQuotationValid
		}))
	}

	const updateNewQuotation = (newQuotationToUpdate: NewQuotationType) => {
		setNewQuotation(newQuotationToUpdate)
	}

	const selectedFreight = (event: { originalEvent?: Event; value?: FreightAndTerms }) => {
		if (event) {
			setNewQuotation((prevState) => ({
				...prevState,
				freight: event.value
			}))
		} else {
			setNewQuotation((prevState) => ({
				...prevState,
				freight: undefined
			}))
		}
	}

	const selectedPaymentTerms = (event: { originalEvent?: Event; value?: FreightAndTerms }) => {
		if (event) {
			setNewQuotation((prevState) => ({
				...prevState,
				paymentTerms: event.value
			}))
		} else {
			setNewQuotation((prevState) => ({
				...prevState,
				paymentTerms: undefined
			}))
		}
	}

	const setResetDataQuotation = () => {
		setDataRFQ((prevState) => ({
			...prevState,
			resetDataQuotation: false
		}))
	}

	const addNewComment = (newCommentData: Comment) => {
		if (newCommentData?.text) {
			client
				.mutate({
					mutation: CREATE_RFQ_COMMENT,
					variables: {
						rfq: searchParams.get('rfq'),
						customer: searchParams.get('customer'),
						text: newCommentData.text
					}
				})
				.then(() => {
					client
						.query({
							query: REQUEST_FOR_QUOTATION_COMMENTS,
							variables: {
								id: searchParams.get('rfq'),
								customer: searchParams.get('customer')
							},
							fetchPolicy: 'no-cache'
						})
						.then((result: ApolloQueryResult<{ RequestForQuotation: RequestForQuotation }>) => {
							setInformationComments(result.data.RequestForQuotation.comments)
							setDataRFQ((prevState) => ({
								...prevState,
								existMessagePending: false
							}))
							setCommentPending({
								text: undefined
							})
						})
						.catch(handleError)
				})
				.catch(handleError)
		}
	}

	const sizeChangeHandler = (commentSectionSize: CommentSectionSize) => {
		const isCommentsSectionExtended: boolean = commentSectionSize === CommentSectionSize.Extended
		if (dataRFQ.showNewQuotation) {
			setDataRFQ((prevState) => ({
				...prevState,
				showButtonsNewQuotation: !isCommentsSectionExtended
			}))
		} else {
			setDataRFQ((prevState) => ({
				...prevState,
				showList: !isCommentsSectionExtended
			}))
		}

		if (!dataRFQ.buttonContainer && !isCommentsSectionExtended) {
			setDataRFQ((prevState) => ({
				...prevState,
				buttonContainer: true
			}))
		}
	}

	const pendingMessageHandler = (text: string) => {
		setCommentPending({
			text: text
		})
		if (dataRFQ.existMessagePending !== !!text) {
			setDataRFQ((prevState) => ({
				...prevState,
				existMessagePending: !!text
			}))
		}
	}

	const showButtonsSection = (showButtons: boolean) => {
		setDataRFQ((prevState) => ({
			...prevState,
			buttonContainer: showButtons
		}))
	}

	const openNewQuotation = () => {
		setDataRFQ((prevState) => ({
			...prevState,
			showTrackInformation: false,
			showMainTableRFQ: false,
			showQuotationDetail: false,
			showButtonsNewQuotation: true,
			showNewQuotation: true,
			showNewQuotationComponent: true,
			createQuotationOpen: true,
			showList: false
		}))
	}

	const validateAllFieldsUncatalogued = () => {
		let unFillItemsUncatalogued = false
		const validateField: Array<boolean> = []
		newQuotation.itemsQuotation.forEach((itemRFQ: ItemQuotation) => {
			if (itemRFQ.status === Constants.STATUS.CONFIRMED) {
				itemRFQ.itemsQ.forEach((itemQuotation: ItemQ) => {
					if (
						itemQuotation.product === undefined ||
						itemQuotation.requiredDate === undefined ||
						itemQuotation.tax === 'taxIVA' ||
						itemQuotation.unitPrice === 0 ||
						itemQuotation.amount === ''
					) {
						validateField.push(false)
					} else {
						validateField.push(true)
					}
				})
			} else {
				if (!itemRFQ.rejectReason) {
					unFillItemsUncatalogued = true
				}
			}
		})
		if (newQuotation.netTotal > 0 && newQuotation.currency === '') {
			setDataRFQ((prevState) => ({
				...prevState,
				currencyQuotationRequired: true
			}))
			unFillItemsUncatalogued = false
		}
		let existDate = false
		if (expiredDate) {
			existDate = true
			setDataRFQ((prevState) => ({
				...prevState,
				dateErrorRequired: false
			}))
		} else {
			setDataRFQ((prevState) => ({
				...prevState,
				dateErrorRequired: true
			}))
		}
		unFillItemsUncatalogued = validateField.every((item: boolean) => item === true)
		setDataRFQ((prevState) => ({
			...prevState,
			quotationSaveDataModal: unFillItemsUncatalogued && existDate && newQuotation.currency !== '',
			saveDiffPrices: false
		}))
	}

	const validateAllFields = () => {
		if (settingValidateCatalogue) {
			let unFillItems = false
			let differentPrices = false
			newQuotation.itemsQuotation.forEach((itemRFQ: ItemQuotation) => {
				if (itemRFQ.status === Constants.STATUS.CONFIRMED) {
					itemRFQ.itemsQ.forEach((itemQuotation: ItemQ) => {
						if (!differentPrices) {
							differentPrices =
								itemQuotation.referencePrice !== parseFloat(itemQuotation.unitPrice as string)
						}
						unFillItems =
							itemQuotation.product === undefined ||
							itemQuotation.amount === '' ||
							parseFloat(itemQuotation.amount as string) === 0 ||
							itemQuotation.requiredDate === undefined ||
							itemQuotation.unitPrice === '' ||
							parseFloat(itemQuotation.amount as string) === 0
					})
				} else {
					if (!itemRFQ.rejectReason) {
						unFillItems = true
					}
				}
			})
			let existDate = false
			if (expiredDate) {
				existDate = true
				setDataRFQ((prevState) => ({
					...prevState,
					requiredDateError: false
				}))
			} else {
				setDataRFQ((prevState) => ({
					...prevState,
					requiredDateError: true
				}))
			}
			if (checkCurrencyList(newQuotation)) {
				setDataRFQ((prevState) => ({
					...prevState,
					saveDiffPrices: differentPrices,
					quotationSaveDataModal: !unFillItems && existDate
				}))
			} else {
				setDataRFQ((prevState) => ({
					...prevState,
					differentCurrenciesModal: true
				}))
			}
		} else {
			validateAllFieldsUncatalogued()
		}
	}

	const cancelQuotation = () => {
		if (dataRFQ.goToList) {
			history.replace(URLS.RFQ)
		} else {
			if (informationRFQItems) {
				const arrayItemsRFQ: Array<ItemQuotation> = informationRFQItems.map(
					(_: InformationRFQItem, index: number) => createDefaultItemQuotation(index)
				)
				setNewQuotation((prevState) => ({
					...prevState,
					itemsQuotation: arrayItemsRFQ,
					netTotal: 0
				}))
			}

			setDataRFQ((prevState) => ({
				...prevState,
				showTrackInformation: true,
				showMainTableRFQ: true,
				showNewQuotation: false,
				showButtonsNewQuotation: false,
				showNewQuotationComponent: false,
				showList: true,
				cancelQuotationModal: false,
				createQuotationOpen: false,
				cleanReasons: false
			}))
			setExpiredDate(null)
			if (!settingValidateCatalogue) {
				setNewQuotation((prevState) => ({
					...prevState,
					freight: resetData.freight,
					paymentTerms: resetData.paymentTerms
				}))
				setDataRFQ((prevState) => ({
					...prevState,
					resetDataQuotation: true
				}))
			}
		}
	}

	const saveQuotation = () => {
		setShowSpinner?.(true)
		const itemsQuotation: Array<QuotationItem> = createItemsQuotationToSave(
			newQuotation,
			informationRFQ,
			settingValidateCatalogue
		)
		client
			.mutate({
				mutation: CREATE_QUOTATION,
				variables: {
					request: searchParams.get('rfq'),
					customer: searchParams.get('customer'),
					total: newQuotation.netTotal,
					currency: newQuotation.currency,
					expiration_date: Formatter.finalDateFormat(expiredDate),
					incoterm: newQuotation.freight?.code,
					payment_term: newQuotation.paymentTerms?.code,
					items: itemsQuotation
				}
			})
			.then(() => {
				addNewComment(commentPending)
				setShowSpinner?.(false)
				resetDataPage()
			})
			.catch(handleError)
	}

	return (
		<div className={styles.containerDetailRFQ}>
			<Header>
				<div>
					<CancelButton
						onClick={() => actionButton('comeBack')}
						translationKey="comebackListText"
						icon={<NetcurioIcons.ArrowBack />}
					/>
					{hasPdfFile && (
						<NetcurioButton
							variant="outlined"
							color="primary"
							endIcon={<NetcurioIcons.Download />}
							onClick={() => actionButton('downloadPdfPo')}
							size="small"
						>
							{t('downloadPo')}
						</NetcurioButton>
					)}
				</div>
				<div />
			</Header>
			<div className={styles.containerTrackTotalMiddle}>
				<div className={styles.mainContainerRFQ}>
					<div className={styles.sticky}>
						<TrackInformationHeader
							informationRFQ={informationRFQ}
							showRejectRFQMessage={dataRFQ.showRejectRFQMessage}
							showClosedRFQMessage={dataRFQ.showClosedRFQMessage}
						/>
					</div>
					{dataRFQ.showTrackInformation && <TrackInformation informationRFQ={informationRFQ} />}
					{dataRFQ.showMainTableRFQ && (
						<MainTableRFQDetail informationRFQItems={informationRFQItems} />
					)}
					{dataRFQ.showQuotationDetail && (
						<QuotationDetail
							hideQuotationDetailComponent={hideQuotationDetailComponent}
							quotationInfo={quotationInfo}
							saveCommentPendingWhenCreateOrder={addNewComment}
							commentPending={commentPending}
							settingValidateCatalogue={settingValidateCatalogue}
						/>
					)}
					{dataRFQ.showNewQuotationComponent && (
						<NewQuotation
							closeNewQuotation={closeNewQuotation}
							settingValidateCatalogue={settingValidateCatalogue}
							newQuotation={newQuotation}
							informationRFQItems={informationRFQItems}
							setNewQuotation={setUpdateNewQuotation}
							fullInformationProducts={fullInformationProducts}
							activateNewQuotationButton={activateNewQuotationButton}
							updateNewQuotation={updateNewQuotation}
							cleanReasons={dataRFQ.cleanReasons}
							resetDataQuotation={dataRFQ.resetDataQuotation}
							allFreights={allFreights}
							allPaymentTerms={allPaymentTerms}
							searchFreight={searchFreight}
							searchPaymentTerms={searchPaymentTerms}
							selectedFreight={selectedFreight}
							selectedPaymentTerms={selectedPaymentTerms}
							setResetDataQuotation={setResetDataQuotation}
						/>
					)}
				</div>
				<div className={classNames(styles.sticky, styles.lateralContainerRFQ)}>
					<CommentsSection
						variant={commentsSectionVariant}
						comments={informationComments}
						onAddComment={addNewComment}
						onSizeChange={sizeChangeHandler}
						onChange={pendingMessageHandler}
					/>
					{dataRFQ.showList && (
						<>
							<div
								className={classNames({
									[styles.allowFlexGrow]: !dataRFQ.buttonContainer
								})}
							>
								<QuotationsList
									informationListQuotations={informationListQuotations}
									showButtons={showButtonsSection}
									showQuotationDetailComponent={getQuotation}
								/>
							</div>
							{dataRFQ.buttonContainer && (
								<ButtonsRFQDetail
									userRole={userRole}
									statusRFQ={informationRFQ.status}
									openNewQuotation={openNewQuotation}
									actionButton={actionButton}
								/>
							)}
						</>
					)}
					{dataRFQ.showButtonsNewQuotation && (
						<ButtonNewQuotation
							expiredDate={expiredDate}
							setExpiredDate={setExpiredDate}
							settingValidateCatalogue={settingValidateCatalogue}
							newQuotation={newQuotation}
							setNewQuotation={setNewQuotation}
							currencies={currencies}
							disabledNewQuotationButton={dataRFQ.disabledNewQuotationButton}
							validateAllFields={validateAllFields}
							statusRFQ={informationRFQ.status}
							userRole={userRole}
							actionButton={actionButton}
							dateErrorRequired={dataRFQ.dateErrorRequired}
							currencyQuotationRequired={dataRFQ.currencyQuotationRequired}
						/>
					)}
				</div>
			</div>
			<ViewAllModalsRFQ
				dataRFQ={dataRFQ}
				setDataRFQ={setDataRFQ}
				cancelQuotation={cancelQuotation}
				addNewComment={addNewComment}
				commentPending={commentPending}
				informationRFQ={informationRFQ}
				errorCodeModal={errorCodeModal}
				saveQuotation={saveQuotation}
				resetDataPage={resetDataPage}
			/>
		</div>
	)
}
