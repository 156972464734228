import {
	NetcurioAutocomplete,
	NetcurioGrid,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	Severity,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import DefaultClient, { ApolloQueryResult, NormalizedCacheObject } from 'apollo-boost'
import React, { FC, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { useCompanySettings } from '../../../../hooks/useCompanySettings'
import {
	Consumption,
	ConsumptionItem,
	ErrorWrapper,
	Invoice,
	PurchaseOrder,
	PurchaseOrderItem
} from '../../../../types'
import { connection } from '../../../../utilities/connection'
import Constants from '../../../../utilities/constants'
import documentType from '../../../../utilities/constants/documentType'
import { showErrorComponent } from '../../../../utilities/errorCode'
import { expiredToken } from '../../../../utilities/expiredToken'
import Formatter from '../../../../utilities/formatter'
import {
	CONSUMPTION,
	PURCHASE_ORDERS_FOR_INVOICE,
	REMOVE_ASSOCIATION,
	SEARCH_CONSUMPTIONS_FOR_INVOICE,
	SEARCH_PURCHASE_ORDERS_FOR_INVOICE
} from '../../graphql'
import styles from '../InvoiceEdit.module.scss'
import { ChangeDocumentTypeModal, ErrorAssociationModal, RemoveDocumentAssociatedModal } from '../Modals'
import { OptionsDocumentType } from '../types'
import { optionsDocumentsType } from '../utilities/defaultValues'

interface AssociateDocumentProps {
	rfcCustomerInDocument: string
	documentAssociate(document?: string, items?: Array<PurchaseOrderItem | ConsumptionItem>): void
	documentSelected: string
	documentReferenceId?: string
	updateDocumentTypeSelected(relatedDocumentType: string): void
	updateInvoiceAssociationData(): void
	referenceType?: string
	customerReferencePORelated?: string
	customerReferenceConsumptionRelated?: string
	invoice?: Invoice
	onChangeAssociation: (value: PurchaseOrder | Consumption) => void
}

const removeNoPOOption = (option: OptionsDocumentType) => option.value !== Constants.DOCUMENT_TYPE.STANDALONE

export const AssociateDocument: FC<AssociateDocumentProps> = ({
	rfcCustomerInDocument,
	documentAssociate,
	documentSelected,
	documentReferenceId,
	updateDocumentTypeSelected,
	updateInvoiceAssociationData,
	referenceType,
	customerReferencePORelated,
	customerReferenceConsumptionRelated,
	invoice,
	onChangeAssociation
}) => {
	const { t } = useTranslation()
	const history = useHistory()
	const debounceRef = useRef<ReturnType<typeof setTimeout>>()
	const client: DefaultClient<NormalizedCacheObject> = useMemo(
		(): DefaultClient<NormalizedCacheObject> => connection(),
		[]
	)
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const location = useLocation()
	const queryParams: URLSearchParams = new URLSearchParams(location.search)
	const [documentTypeSelected, setDocumentTypeSelected] = useState<string>(
		Constants.DOCUMENT_TYPE.PURCHASE_ORDER
	)
	const [tempDocumentTypeSelected, setTempDocumentTypeSelected] = useState<string>()
	const [loading, setLoading] = useState<boolean>(false)
	const [suggestion, setSuggestion] = useState<Array<PurchaseOrder | Consumption>>([])
	const [value, setValue] = useState<PurchaseOrder | Consumption>(null)
	const [tempValue, setTempValue] = useState<PurchaseOrder | Consumption>(null)
	const [errorResponse, setErrorResponse] = useState<ErrorWrapper | undefined>(undefined)
	const [removeDocumentModal, setRemoveDocumentModal] = useState<boolean>(false)
	const [changeDocumentModal, setChangeDocumentModal] = useState<boolean>(false)

	const { companySettings } = useCompanySettings({ rfc: invoice?.receiver?.rfc ?? null, errorHandler })

	const workFlowOptions = useMemo(() => {
		if (!companySettings?.standalone_invoice_workflow_enabled) {
			return optionsDocumentsType.filter(removeNoPOOption)
		}
		return optionsDocumentsType
	}, [companySettings])

	useEffect(() => {
		if (referenceType) {
			setDocumentTypeSelected(
				referenceType === Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER
					? Constants.DOCUMENT_TYPE.PURCHASE_ORDER
					: referenceType
			)
		}
	}, [referenceType])

	useEffect(() => {
		if (referenceType && documentReferenceId) {
			if (
				referenceType === Constants.DOCUMENT_TYPE.PURCHASE_ORDER ||
				referenceType === Constants.DOCUMENT_TYPE.CUSTOMER_PURCHASE_ORDER
			) {
				getPODataForInvoice(documentReferenceId)
			} else if (referenceType === Constants.DOCUMENT_TYPE.CONSUMPTION) {
				getConsumptionDataForInvoice(documentReferenceId)
			}
		}
	}, [documentReferenceId, referenceType])

	const getOptionLabel = (option: PurchaseOrder | Consumption) => {
		return `${Formatter.id(option?.id)} - ${option?.customer_reference ?? t('NAtext')}`
	}

	function errorHandler(error: Error) {
		const errorCode = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setErrorResponse({
				code: errorCode,
				message: error.message
			})
		}
		setLoading(false)
	}

	const getSuggestion = (searchText: string) => {
		if (searchText === '' && documentSelected) {
			setRemoveDocumentModal(true)
		} else {
			setLoading(true)
			if (debounceRef.current) {
				clearTimeout(debounceRef.current)
			}
			debounceRef.current = setTimeout(() => {
				if (documentTypeSelected === Constants.DOCUMENT_TYPE.PURCHASE_ORDER) {
					searchPOForInvoice(searchText)
				} else if (documentTypeSelected === Constants.DOCUMENT_TYPE.CONSUMPTION) {
					searchConsumptionsForInvoice(searchText)
				}
			}, 500)
		}
	}

	const searchPOForInvoice = (searchText: string) => {
		client
			.query({
				query: SEARCH_PURCHASE_ORDERS_FOR_INVOICE,
				variables: {
					customer: rfcCustomerInDocument,
					search_text: searchText
				}
			})
			.then((result: ApolloQueryResult<{ SearchPurchaseOrdersForInvoice: Array<PurchaseOrder> }>) => {
				setSuggestion(searchText === '' ? [] : result.data.SearchPurchaseOrdersForInvoice)
			})
			.catch(errorHandler)
			.finally(() => {
				setLoading(false)
			})
	}

	const getPODataForInvoice = (idDocument: string) => {
		client
			.query({
				query: PURCHASE_ORDERS_FOR_INVOICE,
				variables: {
					customer: rfcCustomerInDocument,
					id: idDocument
				}
			})
			.then((result: ApolloQueryResult<{ PurchaseOrderForInvoice: PurchaseOrder }>) => {
				if (result.data.PurchaseOrderForInvoice) {
					setValue(result.data.PurchaseOrderForInvoice)
					documentAssociate(idDocument, result.data.PurchaseOrderForInvoice.items)
				} else {
					setValue({
						id: idDocument,
						customer_reference: customerReferencePORelated
					} as PurchaseOrder)
				}
			})
			.catch(errorHandler)
			.finally(() => {
				setLoading(false)
			})
	}

	const searchConsumptionsForInvoice = (searchText: string) => {
		client
			.query({
				query: SEARCH_CONSUMPTIONS_FOR_INVOICE,
				variables: {
					customer: rfcCustomerInDocument,
					search_text: searchText
				}
			})
			.then((result: ApolloQueryResult<{ ConsumptionsForInvoice: Array<Consumption> }>) => {
				setSuggestion(searchText === '' ? [] : result.data.ConsumptionsForInvoice)
			})
			.catch(errorHandler)
			.finally(() => {
				setLoading(false)
			})
	}

	const getConsumptionDataForInvoice = (idDocument: string) => {
		client
			.query({
				query: CONSUMPTION,
				variables: {
					customer: rfcCustomerInDocument,
					id: idDocument
				}
			})
			.then((result: ApolloQueryResult<{ Consumption: Consumption }>) => {
				if (result.data.Consumption) {
					setValue(result.data.Consumption)
					documentAssociate(idDocument, result.data.Consumption.items)
				} else {
					setValue({
						id: idDocument,
						customer_reference: customerReferenceConsumptionRelated
					} as Consumption)
				}
			})
			.catch(errorHandler)
			.finally(() => {
				setLoading(false)
			})
	}

	const selectedOption = (selected: PurchaseOrder | Consumption) => {
		if (value?.id && selected.id !== value?.id) {
			setTempValue(selected)
			setRemoveDocumentModal(true)
		} else {
			setValue(selected)
			documentAssociate(selected?.id, selected.items)
		}
	}

	const removeAssociation = () => {
		setShowSpinner && setShowSpinner(true)
		client
			.mutate({
				mutation: REMOVE_ASSOCIATION,
				variables: {
					uuid: queryParams.get('invoice') ?? ''
				}
			})
			.then(() => {
				documentAssociate()
				updateInvoiceAssociationData()
				if (tempValue) {
					setValue(tempValue)
					documentAssociate(tempValue?.id, tempValue.items)
					setTempValue(null)
				} else {
					setValue(null)
				}
				setChangeDocumentModal(false)
			})
			.catch(errorHandler)
			.finally(() => {
				setShowSpinner && setShowSpinner(false)
			})
	}

	const handleOnCancelRemoveDocument = () => {
		setRemoveDocumentModal(false)
	}

	const handleOnCancelChangeDocument = () => {
		setChangeDocumentModal(false)
		setTempDocumentTypeSelected(undefined)
	}

	const handleOnAcceptRemoveDocument = () => {
		removeAssociation()
		setRemoveDocumentModal(false)
	}

	const handleOnAcceptChangeDocument = () => {
		removeAssociation()
		updateDocumentTypeSelected(tempDocumentTypeSelected)
		setDocumentTypeSelected(tempDocumentTypeSelected)
	}

	const handleOnAcceptError = () => {
		history.goBack()
	}

	useEffect(() => {
		onChangeAssociation(value)
	}, [value])

	return (
		<>
			<NetcurioGrid item className={styles.titleContainerAssociateDocument}>
				<p className={styles.titleAssociateDocument}>{t('workFlow')}</p>
			</NetcurioGrid>
			<NetcurioGrid item className={styles.bodyContainerAssociateDocument}>
				<p className={styles.bodyTextAssociateDocument}>{t('selectInvoiceWorkFlow')}</p>
				<NetcurioGrid item paddingBottom="1rem">
					<NetcurioSelect
						minWidth="100%"
						value={documentTypeSelected}
						onChange={(event: NetcurioSelectChangeEvent) => {
							if (documentSelected) {
								setChangeDocumentModal(true)
								setTempDocumentTypeSelected(event.target.value)
							} else {
								setDocumentTypeSelected(event.target.value)
								updateDocumentTypeSelected(event.target.value)
							}
						}}
						size="small"
					>
						{workFlowOptions.map((item: OptionsDocumentType) => (
							<NetcurioMenuItem key={item.value} value={item.value}>
								<span>{item.label}</span>
							</NetcurioMenuItem>
						))}
					</NetcurioSelect>
				</NetcurioGrid>
				{documentTypeSelected !== documentType.STANDALONE && (
					<NetcurioGrid item>
						<NetcurioAutocomplete
							height="smaller"
							size="small"
							variant="outlined"
							options={suggestion}
							value={value}
							onInputValueChange={getSuggestion}
							onSelectValue={selectedOption}
							loading={loading}
							getOptionLabel={getOptionLabel}
							isOptionEqualToValue={(option: PurchaseOrder | Consumption) =>
								option.id === option.id
							}
							disableClearable
						/>
					</NetcurioGrid>
				)}
			</NetcurioGrid>
			<RemoveDocumentAssociatedModal
				open={removeDocumentModal}
				onClose={() => handleOnCancelRemoveDocument()}
				onAccept={() => handleOnAcceptRemoveDocument()}
			/>
			<ChangeDocumentTypeModal
				open={changeDocumentModal}
				onClose={() => handleOnCancelChangeDocument()}
				onAccept={() => handleOnAcceptChangeDocument()}
			/>
			<ErrorAssociationModal
				open={errorResponse !== undefined}
				onAccept={handleOnAcceptError}
				title={t('showError')}
				bodyText={t('errorCodeText', { idCode: errorResponse?.code })}
				textButton={t('acceptTextButton')}
				headerTitleSeverity={Severity.Error}
			/>
		</>
	)
}
