import { Filter, getCurrentUser, Roles, URLS } from '@netcurio-ui/common'
import { Header, ShowRootElementsContext } from '@netcurio-ui/components'
import DefaultClient, { NormalizedCacheObject } from 'apollo-boost'
import React, { ReactElement, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AddButton } from '../../components/HeaderButtons/AddButton'
import { ExportButton } from '../../components/HeaderButtons/ExportButton'
import { useCompanySettings } from '../../hooks/useCompanySettings'
import '../../style.scss'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { expiredToken } from '../../utilities/expiredToken'
import { downloadExcelFile } from '../../utilities/file-handling/download-excel-file'
import { FileDescriptor } from '../../utilities/file-handling/file-descriptor'
import listHelper from '../../utilities/listHelper'
import UserInformation from '../../utilities/userInformation'
import { ErrorModal } from '../consumption/Modals/ErrorModal/ErrorModal'
import { ConsumptionTable } from './consumptionTable'
import * as queries from './queries'
import {
	TypesConsumption,
	TypesDataConsumption,
	TypesDataConsumptionsList,
	TypesFilterToApply,
	TypesModal,
	TypesUpdateFilter
} from './types'

export const ConsumptionList = (): ReactElement => {
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [showBarLoader, setShowBarLoader] = useState<boolean>()
	const history = useHistory<string>()
	const initialSort = 'created_at'
	const client = useMemo((): DefaultClient<NormalizedCacheObject> => connection(), [])
	const [userRole, setUserRole] = useState<Roles>()
	const currentUser = getCurrentUser()

	const [consumptionList, setConsumptionList] = useState<TypesDataConsumptionsList>({
		totalRows: undefined,
		rowsActual: undefined,
		limitRows: undefined,
		filterContainerBar: false,
		deleteRange: false,
		orderList: Constants.ORDER.DESC,
		actualFilterData: undefined,
		fieldList: initialSort,
		dataFilters: {},
		dataFiltersArray: [],
		initialSort: initialSort,
		dataConsumption: {}
	})
	const [modal, setModal] = useState<TypesModal>({
		errorModal: false,
		errorCode: undefined
	})
	const [activePagination, setActivePagination] = useState<boolean>(false)
	const [stopPagination, setStopPagination] = useState<boolean>(true)
	const isCustomer = userRole === Roles.CUSTOMER

	const { companySettings } = useCompanySettings({
		rfc: currentUser?.company?.rfc ?? null,
		errorHandler: (error: Error) => handleError(error)
	})

	const showInvoiceBtn = isCustomer

	const isNewConsumptionButtonDisabled = isCustomer && companySettings?.process_invoice_in_external_system

	useEffect(() => {
		const finalHeight: number = window.innerHeight - 95
		const numberRows: number = Math.round(finalHeight / 45) + 2
		setShowSpinner && setShowSpinner(true)
		setUserRole(UserInformation.getCompanyRole())
		setConsumptionList((prevState) => ({
			...prevState,
			limitRows: numberRows,
			rowsActual: numberRows
		}))
	}, [])

	useEffect(() => {
		if (consumptionList.limitRows !== undefined) {
			filteringConsumption(0, initialSort, '', initialSort)
		}
	}, [consumptionList.limitRows])

	useEffect(() => {
		if (activePagination && stopPagination) {
			setActivePagination(false)
			setStopPagination(false)
			filteringConsumption(
				consumptionList.rowsActual,
				'',
				'',
				'',
				undefined,
				undefined,
				undefined,
				'none'
			)
		}
	}, [activePagination, stopPagination])

	// useEffect(() => showModal(), [modal])

	const filteringConsumption = (
		skip: number,
		sortField: string,
		sortOrder: string,
		elementFilterActual: string,
		valueFilter?: string,
		initRange?: number,
		finalRange?: number,
		filterRemove?: string
	) => {
		let fieldListData: string = consumptionList.fieldList,
			orderListData: string = consumptionList.orderList,
			columnFilterActual: string = consumptionList.actualFilterData,
			currentList: TypesDataConsumption = {
				...consumptionList.dataConsumption
			}

		if (sortOrder !== '') {
			orderListData = sortOrder
			setConsumptionList((prevState) => ({
				...prevState,
				orderList: sortOrder
			}))
		}
		if (sortOrder) {
			fieldListData = sortField
			setConsumptionList((prevState) => ({
				...prevState,
				fieldList: sortField
			}))
		}

		if (elementFilterActual !== '') {
			columnFilterActual = elementFilterActual
			setConsumptionList((prevState) => ({
				...prevState,
				actualFilterData: elementFilterActual
			}))
		}
		let typeFilterActual: string,
			initRangeActual: number,
			finalRangeActual: number,
			valueFilterActual: string = valueFilter
		switch (columnFilterActual) {
			case 'id':
			case 'customer_reference':
			case 'branch_office':
			case 'customer':
			case 'created_by':
				typeFilterActual = 'wildcard'
				break
			case 'created_at':
				typeFilterActual = 'date'
				initRangeActual = initRange
				finalRangeActual = finalRange
				break
			default:
				break
		}
		if (skip > 0) {
			const rowActual: number = consumptionList.rowsActual + consumptionList.limitRows
			setConsumptionList((prevState) => ({
				...prevState,
				rowsActual: rowActual
			}))
		} else {
			setConsumptionList((prevState) => ({
				...prevState,
				rowsActual: prevState.limitRows,
				dataConsumption: {}
			}))
			currentList = {}
		}
		const filterToApply: Array<Filter> = activeFilterToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField
		)
		queryFilteringConsignment(fieldListData, orderListData, filterToApply, skip, currentList)
	}

	const activeFilterToApply = (
		typeFilterActual: string,
		valueFilterActual: string,
		initRangeActual: number,
		finalRangeActual: number,
		columnFilterActual: string,
		filterRemove: string,
		sortField: string
	): Array<Filter> => {
		const resultFiltersToApply = listHelper.generateFiltersToApply(
			typeFilterActual,
			valueFilterActual,
			initRangeActual,
			finalRangeActual,
			columnFilterActual,
			filterRemove,
			sortField,
			consumptionList.dataFilters,
			{}
		) as TypesFilterToApply

		const objUpdate: TypesUpdateFilter = resultFiltersToApply.objectForStateUpdate

		setConsumptionList((prevState) => ({
			...prevState,
			dataFilters: objUpdate.dataFilters,
			dataFiltersArray: objUpdate.dataFiltersArray,
			dataStatusFilter: objUpdate.dataStatusFilter,
			deleteRange: objUpdate.deleteRange,
			filterContainerBar: objUpdate.filterContainerBar
		}))

		return resultFiltersToApply.filterToApply
	}

	const queryFilteringConsignment = (
		fieldListData: string,
		orderListData: string,
		filterToApply: Array<Filter>,
		skip: number,
		currentList: TypesDataConsumption
	) => {
		const copyFilterToApply: Array<Filter> = JSON.parse(JSON.stringify(filterToApply))
		copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
		client
			.query({
				query: queries.CONSUMPTIONS,
				variables: {
					limit: consumptionList.limitRows,
					skip: skip,
					sort_field: fieldListData,
					sort_order: orderListData,
					filter: copyFilterToApply
				}
			})
			.then((result) => {
				let interCount: number = skip,
					data: TypesDataConsumption = { ...currentList },
					obj: Array<TypesConsumption> = result.data.Consumptions.list
				for (const keyName in obj) {
					interCount++
					data[interCount] = obj[keyName]
				}
				setConsumptionList((prevState) => ({
					...prevState,
					totalRows: result.data.Consumptions.total,
					dataConsumption: data
				}))
				setStopPagination(true)
				setShowSpinner(false)
				setShowBarLoader(false)
			})
			.catch(handleError)
	}

	const handleError = (error: Error) => {
		setShowSpinner(false)
		setShowBarLoader(false)
		const errorCode: string = showErrorComponent(error)
		if (!expiredToken(errorCode)) {
			setConsumptionList((prevState) => ({
				...prevState,
				dataConsumption: {}
			}))
			setStopPagination(true)
			setModal({
				errorModal: true,
				errorCode
			})
		}
	}

	const closeFilterContainerBar = () => {
		listHelper.closeFilterContainerBar(filteringConsumption, consumptionList.initialSort)
		setConsumptionList((prevState) => ({
			...prevState,
			deleteRange: true
		}))
	}

	const deleteFilterActive = (indexObject: string) =>
		listHelper.deleteFilterActive(
			indexObject,
			consumptionList.dataFilters,
			closeFilterContainerBar,
			filteringConsumption
		)
	const downloadReportExcel = () => {
		if (Object.keys(consumptionList.dataConsumption).length > 0) {
			setShowSpinner && setShowSpinner(true)
			const copyFilterToApply: Array<Filter> = JSON.parse(
				JSON.stringify(consumptionList.dataFiltersArray)
			)
			copyFilterToApply.forEach(listHelper.applyTimeZoneDate)
			const body: string = JSON.stringify({
				filters: copyFilterToApply,
				sort_field: consumptionList.fieldList,
				sort_order: consumptionList.orderList
			})
			listHelper
				.generateReportList(body, 'consumptions')
				.then(async (res) => {
					if (res.ok) {
						res.json().then((responseJson: FileDescriptor) => {
							downloadExcelFile(responseJson)
						})
						setShowSpinner && setShowSpinner(false)
					} else {
						const error: Error = await res.json()
						handleError(error)
					}
				})
				.catch(handleError)
		}
	}

	const actionHeaderButton = () => {
		history.push(URLS.NEW_CONSUMPTION)
	}

	return (
		<>
			<Header>
				<div>
					{showInvoiceBtn && (
						<AddButton
							onClick={actionHeaderButton}
							translationKey="newConsumption"
							disabled={isNewConsumptionButtonDisabled}
						/>
					)}
				</div>
				<div>
					<ExportButton onClick={downloadReportExcel} />
				</div>
			</Header>
			<ConsumptionTable
				userRole={userRole}
				dataConsumption={consumptionList.dataConsumption}
				totalRows={consumptionList.totalRows}
				rowsActual={consumptionList.rowsActual}
				filterContainerBar={consumptionList.filterContainerBar}
				filteringConsumption={filteringConsumption}
				closeFilterContainerBar={closeFilterContainerBar}
				dataFiltersArray={consumptionList.dataFiltersArray}
				deleteFilterActive={deleteFilterActive}
				deleteRange={consumptionList.deleteRange}
				setActivePagination={setActivePagination}
				showBarLoader={showBarLoader}
				setShowBarLoader={setShowBarLoader}
			/>
			<ErrorModal open={!!modal?.errorCode} errorCode={modal?.errorCode} />
		</>
	)
}
