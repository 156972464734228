import { AUTH, RELATION_KEY_URL, URLS, getCompany, getLanguage } from '@netcurio-ui/common'
import {
	AdapterDayjs,
	CircularSpinner,
	LocalizationProvider,
	NetcurioSnackbarProvider,
	NetcurioTheme,
	ShowRootContextProvider
} from '@netcurio-ui/components'
import React, { useCallback, useState } from 'react'
import { render } from 'react-dom'
import MainModal from './components/dialogModal/mainModal'
import { useAuth } from './hooks/useAuth'
import { useStorageListener } from './hooks/useStorageListener'
import { modalCompanyChange } from './utilities/modalExternCompanyChange'
import { validationToRedirection } from './utilities/redirectHelper'
import HomeView from './views/homeView'
import MainView from './views/mainView'

const App = () => {
	const [state, setState] = useState({
		homeView: false,
		mainView: true
	})

	const { mainView } = state

	const homeView = () => {
		validationToRedirection(false)
			.then(() => {
				setState({ homeView: true, mainView: false })
				return <HomeView />
			})
			.catch((error) => {
				console.error(error)
			})
		return null
	}

	const renderView = () => {
		if (mainView) {
			return <MainView homeView={homeView} />
		}
		return <HomeView />
	}

	const acceptActionModal = useCallback(() => {
		const company = getCompany()
		const homepage = company.homepage
		const url = homepage ? URLS[RELATION_KEY_URL[homepage]] : URLS.PO_LIST
		location.href = url
	}, [])

	const showModal = useCallback(() => {
		const parentModal = document.getElementById('parentModal')
		const element = (
			<MainModal
				fillModal={modalCompanyChange()}
				acceptActionModal={acceptActionModal}
				errorModalShow={true}
				errorByActionClient={true}
			/>
		)
		if (parentModal) {
			render(element, parentModal)
		}
	}, [acceptActionModal])

	useAuth({
		onTokenExpired: () => {
			setState({ homeView: false, mainView: true })
		}
	})
	useStorageListener(AUTH.NETCURIO_USER, showModal)

	return (
		<NetcurioTheme>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={getLanguage()}>
				<NetcurioSnackbarProvider>
					<ShowRootContextProvider>
						<div className={'appDiv'}>
							<CircularSpinner idSpinner={'generalSpinner'} />
							{renderView()}
						</div>
					</ShowRootContextProvider>
				</NetcurioSnackbarProvider>
			</LocalizationProvider>
		</NetcurioTheme>
	)
}

export default App
