import gql from 'graphql-tag'

export const ASSOCIATE_INVOICE = gql`
	mutation associateInvoice(
		$uuid: ID!
		$related_document_type: String!
		$related_document_id: ID!
		$items: [InvoiceAssociationItem!]!
	) {
		associateInvoice(
			uuid: $uuid
			related_document_type: $related_document_type
			related_document_id: $related_document_id
			items: $items
		) {
			code
			message
		}
	}
`
