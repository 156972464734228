import logo from '@netcurio-ui/assets/src/images/secondaryBlueNavyLogo.svg'
import coverImage from '@netcurio-ui/assets/src/imagesNewDesign/landingPageLogin.jpg'
import coverImageWebp from '@netcurio-ui/assets/src/imagesNewDesign/landingPageLogin.webp'
import { URLS } from '@netcurio-ui/common'
import {
	NetcurioButton,
	NetcurioGrid,
	NetcurioTextField,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import { Auth } from 'aws-amplify'
import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import { render } from 'react-dom'
import { useTranslation } from 'react-i18next'
import HomeView from '../../app'
import MainModal from '../../components/dialogModal/mainModal'
import { connection } from '../../utilities/connection'
import Constants from '../../utilities/constants'
import { showErrorComponent } from '../../utilities/errorCode'
import { getUrlParameter } from '../../utilities/getUrlParameter'
import { validationToRedirection } from '../../utilities/redirectHelper'
import UserInformation from '../../utilities/userInformation'
import { RegisterCompanyModal } from '../registerCompanyModal/RegisterCompanyModal'
import {
	ShowRegisterCompanyContext,
	ShowRegisterCompanyProvider
} from '../registerCompanyModal/ShowRegisterCompanyContext'
import { DefaultCompanyBlockModal } from './Modals/DefaultCompanyBlockModal/DefaultCompanyBlockModal'
import styles from './login.module.scss'
import { modalDefaultCompanyBlockOrUnsubscribed } from './modalView'
import * as queries from './queries'

export const LoginComponent = () => {
	const { t } = useTranslation()

	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const { setIsOpenRegisterCompanyModal, topMessage, setTopMessage } =
		useContext(ShowRegisterCompanyContext)
	const client = connection()
	const modalNames = {
		defaultCompanyBlockModal: 'DCBM',
		defaultCompanyUnsubscribedModal: 'DCUM',
		newCompanyModal: 'NCM'
	}

	const [valueUserName, setValueUserName] = useState('')
	const [valueUserPassword, setValueUserPassword] = useState('')
	const [borderRedPass, setBorderRedPass] = useState(false)
	const [borderRedEmail, setBorderRedEmail] = useState(false)
	const [errorMessageLogin, setErrorMessageLogin] = useState('')
	const [pleaseCheck, setPleaseCheck] = useState(false)
	const [activeModal, setActiveModal] = useState('')
	const [blockDefaultCompany, setBlockDefaultCompany] = useState<boolean>(false)

	useEffect(() => {
		if (sessionStorage.getItem('correctMessage') === 'userRegistered') {
			sessionStorage.removeItem('correctMessage')
			setTopMessage && setTopMessage('userRegistered')
		} else if (sessionStorage.getItem('correctMessage') === Constants.ERROR.INVALID_TOKEN) {
			sessionStorage.removeItem('correctMessage')
			setTopMessage && setTopMessage('invalidToken')
		}
		if (getUrlParameter('company_registration')) {
			setIsOpenRegisterCompanyModal && setIsOpenRegisterCompanyModal(true)
		}
	}, [])

	useEffect(() => {
		showModal()
	}, [activeModal])

	function redirectPasswordRecovery() {
		location.href = URLS.PASSWORD_RECOVERY
	}

	function loginSubmit(e: React.FormEvent<HTMLFormElement>) {
		setShowSpinner && setShowSpinner(true)
		e.preventDefault()
		if (!valueUserName) {
			setBorderRedEmail(true)
			setShowSpinner && setShowSpinner(false)
		}
		if (!valueUserPassword) {
			setBorderRedPass(true)
			setShowSpinner && setShowSpinner(false)
		}
		if (valueUserName && valueUserPassword) {
			if (!valueUserName.match(Constants.REGEX.EMAIL)) {
				setErrorMessageLogin('emailNoValidText')
				setPleaseCheck(false)
				setShowSpinner && setShowSpinner(false)
				return false
			}
		} else {
			setErrorMessageLogin('fieldNoFillText')
			setPleaseCheck(false)
			setShowSpinner && setShowSpinner(false)
			return false
		}
		setErrorMessageLogin('')
		Auth.signIn({ username: valueUserName, password: valueUserPassword })
			.then(() => {
				client
					.mutate({
						mutation: queries.LOGIN_USER
					})
					.then((result) => {
						UserInformation.setCurrentUser(result.data.loginUser, undefined)
						validationToRedirection(true)
							.then((userStatus) => {
								if (userStatus === Constants.USER_STATUS.BLOCKED) {
									setBlockDefaultCompany(true)
									setShowSpinner && setShowSpinner(false)
								} else if (userStatus === Constants.USER_STATUS.UNSUBSCRIBED) {
									setActiveModal(modalNames.defaultCompanyUnsubscribedModal)
									setShowSpinner && setShowSpinner(false)
								} else if (userStatus === Constants.AUTH.ALLCOMPANIESBLOCKED) {
									localStorage.clear()
									Auth.signOut()
										.then()
										.catch((err) => console.error(err))
									setErrorMessageLogin('fullCompanyBlock')
									setShowSpinner && setShowSpinner(false)
								} else {
									return <HomeView />
								}
							})
							.catch((error) => {
								console.error(error)
							})
					})
					.catch((error) => {
						Auth.signOut()
							.then()
							.catch((err) => console.error(err))
						if (
							error.graphQLErrors !== undefined &&
							error.graphQLErrors[0] !== undefined &&
							error.graphQLErrors[0].code === Constants.AUTH.BAD_CREDENTIALS
						) {
							setErrorMessageLogin('userNoValidText')
							setPleaseCheck(true)
							setBorderRedEmail(true)
						} else {
							setErrorMessageLogin('failedText')
							setPleaseCheck(false)
						}
						setShowSpinner && setShowSpinner(false)
					})
			})
			.catch(handleError)
			.finally(() => {
				setPleaseCheck(true)
			})
	}

	function clickInput(borderRemove: string) {
		if (borderRemove === 'borderEmail') {
			setBorderRedEmail(false)
		}
		if (borderRemove === 'borderPass') {
			setBorderRedPass(false)
		}
	}
	//TODO: Figure out when this modal is triggered. If it isn´t in use, eliminate it.
	function showModal() {
		const parentModal = document.getElementById('parentModalMainView')
		let element

		if (activeModal === modalNames.defaultCompanyUnsubscribedModal) {
			element = (
				<MainModal
					fillModal={modalDefaultCompanyBlockOrUnsubscribed('defaultCompanyUnsubscribed')}
					acceptActionModal={acceptActionModal}
					errorModalShow={true}
					errorByActionClient={true}
				/>
			)
		}
		if (parentModal) {
			render(element, parentModal)
		}
	}

	function acceptActionModal(action: string) {
		if (action === 'registerCompany') {
			setActiveModal('')
		}
	}

	const redirectUserSelectCompany = () => {
		location.href = URLS.SELECT_COMPANY_AT_LOGIN
		setBlockDefaultCompany(false)
	}
	const handleError = (error: Error) => {
		console.log(error)
		const newErrorCode = showErrorComponent(error)
		setErrorMessageLogin(newErrorCode === 'NotAuthorizedException' ? 'userNoValidText' : newErrorCode)
		setShowSpinner && setShowSpinner(false)
	}

	return (
		<NetcurioGrid container alignContent="flex-start" justifyContent="flex-start" className={styles.body}>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={2}>
				<form onSubmit={loginSubmit}>
					<div className={styles.buttonsPosition}>
						<div>
							<img src={logo} className={styles.logo} alt="Netcurio" />
						</div>
						{topMessage && (
							<div className={styles.topMessageContainer}>
								<div
									className={`${styles.msgStructure} ${
										topMessage === 'userRegistered' || topMessage === 'companyRegister'
											? styles.colorSuccessMsg
											: styles.colorErrorMsg
									}`}
								>
									{t(topMessage)}
								</div>
							</div>
						)}
						<div className={styles.spaceBetweenTitle}>
							<span className={styles.loginLabel}>{t('labelLoginTitle')}</span>
						</div>
						<div className={styles.spaceBetweenInputs}>
							<NetcurioTextField
								error={borderRedEmail}
								size="small"
								variant="outlined"
								fullWidth
								label={t('placeholderUserName')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValueUserName(e.target.value.replace(' ', ''))
									clickInput('borderEmail')
								}}
							/>
						</div>
						<div>
							<NetcurioTextField
								type="password"
								error={borderRedPass}
								size="small"
								variant="outlined"
								fullWidth
								label={t('placeHolderPassword')}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									setValueUserPassword(e.target.value)
									clickInput('borderPass')
								}}
							/>
						</div>
						{errorMessageLogin && (
							<div>
								<p className={classNames(styles.msgStructure, styles.colorErrorMsg)}>
									{t(errorMessageLogin)}
									<br />
									{pleaseCheck ? t('PleaseCheck') : ''}
								</p>
							</div>
						)}
						<div>
							<p className={styles.forgottenPasswordLink} onClick={redirectPasswordRecovery}>
								{t('forgotMyPasswordText')}
							</p>
						</div>
						<div>
							<NetcurioButton fullWidth variant="contained" size="small" type="submit">
								{t('loginTextButton')}
							</NetcurioButton>
						</div>
						<hr className={styles.divider} />
						<div>
							<NetcurioButton
								fullWidth
								variant="outlined"
								onClick={() => {
									setIsOpenRegisterCompanyModal && setIsOpenRegisterCompanyModal(true)
								}}
								size="small"
							>
								{t('registerCompany')}
							</NetcurioButton>
						</div>
					</div>
				</form>
			</NetcurioGrid>
			<NetcurioGrid item xs={1} />
			<NetcurioGrid item xs={8}>
				<link rel="preload" as="image" href={coverImageWebp} />
				<picture className={styles.coverImage}>
					<source type="image/webp" srcSet={coverImageWebp} />
					<source type="image/png" srcSet={coverImage} />
					<img src={coverImage} alt="" />
				</picture>
				<div className={styles.textPosition}>
					<p className={styles.whiteText}>{t('loginTextBanner')}</p>
				</div>
			</NetcurioGrid>
			<RegisterCompanyModal />
			<DefaultCompanyBlockModal open={blockDefaultCompany} onAccept={redirectUserSelectCompany} />
		</NetcurioGrid>
	)
}

export const MainViewLoginComponent = () => {
	return (
		<ShowRegisterCompanyProvider>
			<LoginComponent />
		</ShowRegisterCompanyProvider>
	)
}
