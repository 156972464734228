import { getCurrentUser, Roles } from '@netcurio-ui/common'
import {
	NetcurioAlert,
	NetcurioCheckbox,
	NetcurioFormControl,
	NetcurioFormControlLabel,
	NetcurioFormGroup,
	NetcurioInputLabel,
	NetcurioMenuItem,
	NetcurioSelect,
	NetcurioSelectChangeEvent,
	NetcurioTextField,
	Severity,
	ShowRootElementsContext
} from '@netcurio-ui/components'
import classNames from 'classnames'
import { t } from 'i18next'
import React, { ChangeEvent, FC, useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DropdownItem } from '../../../../../types'
import Constants from '../../../../../utilities/constants'
import { showErrorComponent } from '../../../../../utilities/errorCode'
import { expiredToken } from '../../../../../utilities/expiredToken'
import UserInformation from '../../../../../utilities/userInformation'
import { createInvitationHelper } from '../createInvitationHelper'
import './newUserModal.scss'
import { ErrorUserFormData, NewUserFormProps, UserFormData } from './types'

const userTypesItems: Array<DropdownItem> = [
	{ label: t(Constants.USER_TYPES.STANDARD), value: false },
	{ label: t(Constants.USER_TYPES.ADMINISTRATOR), value: true }
]

const rolesItems: Array<DropdownItem> = [
	{ label: t('customerFilterName'), value: Roles.CUSTOMER },
	{ label: t('supplierFilterName'), value: Roles.SUPPLIER }
]

/**
 * @param sendUserForm: <boolean> is a flag for send invitation
 * @param setIsValidForm: <function> is a function when form is valid
 * @param closeModal: <function> is a function for close the modal
 * @param setSendUserForm: <function> is a function for reset the flag sendUserForm
 * @return component <ReactNode>
 * */
export const NewUserForm: FC<NewUserFormProps> = ({
	sendUserForm,
	setSendUserForm,
	setIsValidForm,
	closeModal
}) => {
	const history = useHistory<string>()
	const { setShowSpinner } = useContext(ShowRootElementsContext)
	const [serverErrorMessage, setServerErrorMessage] = useState<string>(null)
	const [formUser, setFormUser] = useState<UserFormData>({
		valueEmail: '',
		isAdmin: { label: t(Constants.USER_TYPES.STANDARD), value: false },
		enrollmentRequestApprover: false,
		role: t('placeholderRolDropdown')
	})
	const [errorUserForm, setErrorUsersForm] = useState<ErrorUserFormData>({
		emailError: false,
		missingFields: false
	})
	const [userRole, setUserRole] = useState<Roles>()

	useEffect(() => {
		setUserRole(UserInformation.getCompanyRole())
	}, [])

	useEffect(() => {
		validateUserForm()
	}, [formUser])

	useEffect(() => {
		if (sendUserForm) {
			sendUserFormInvitation()
		}
	}, [sendUserForm])

	const validateUserForm = () => {
		if (formUser.valueEmail !== '' && !formUser.valueEmail.match(Constants.REGEX.EMAIL)) {
			setErrorUsersForm((prevState) => ({
				...prevState,
				emailError: true
			}))
		} else {
			setErrorUsersForm((prevState) => ({
				...prevState,
				emailError: false
			}))
		}
		if (formUser.role !== '' && !formUser.role) {
			setErrorUsersForm((prevState) => ({
				...prevState,
				missingFields: true
			}))
		} else {
			setErrorUsersForm((prevState) => ({
				...prevState,
				missingFields: false
			}))
		}
	}

	useEffect(() => {
		if (
			formUser.role != t('placeholderRolDropdown') &&
			formUser.valueEmail &&
			!errorUserForm.emailError &&
			!errorUserForm.missingFields
		) {
			setIsValidForm(true)
		} else {
			setIsValidForm(false)
		}
	}, [formUser, errorUserForm])

	const sendUserFormInvitation = () => {
		setSendUserForm(false)
		setShowSpinner && setShowSpinner(true)
		createInvitationHelper({
			emailInvite: formUser.valueEmail,
			roleInvite: formUser.role,
			isAdminInvite: formUser['isAdmin']['value'],
			enrollmentRequestApprover: formUser.enrollmentRequestApprover
		})
			.then((response) => {
				if (response.route) {
					closeModal()
					history.push(response.route)
				}
			})
			.catch((error) => {
				const idCode = showErrorComponent(error)
				if (!expiredToken(idCode)) {
					setServerErrorMessage(t('errorCodeText', { idCode }))
				}
			})
			.finally(() => {
				setShowSpinner(false)
			})
	}
	const handleTypeOfUser = (event: NetcurioSelectChangeEvent) => {
		const newLabel = event.target.value
		const newValue = userTypesItems.find((item) => item.label === newLabel)?.value
		setFormUser({ ...formUser, isAdmin: { label: newLabel, value: newValue } })
	}

	return (
		<>
			<div className="new-user-modal">
				<div className={classNames('new-user-container')}>
					<div>
						<div className="container-input">
							<NetcurioTextField
								fullWidth
								label={t('userText')}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									event.persist()
									setFormUser((prevState) => ({
										...prevState,
										valueEmail: event.target.value
									}))
								}}
								error={errorUserForm.emailError}
							/>
							{errorUserForm.emailError && (
								<span className="message-error">{t('emailNotValidText')}</span>
							)}
						</div>
					</div>
					<div className="margin-top-25">
						<NetcurioFormControl fullWidth>
							<NetcurioInputLabel id="userType">{t('userType')}</NetcurioInputLabel>
							<NetcurioSelect
								labelId="userType"
								variant="outlined"
								label={t('userType')}
								displayEmpty
								fullWidth
								value={formUser['isAdmin']['label']}
								onChange={(event) => handleTypeOfUser(event)}
							>
								{userTypesItems.map((item, i) => (
									<NetcurioMenuItem key={i} value={item.label}>
										<span>{item.label}</span>
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</NetcurioFormControl>
					</div>
					{formUser['isAdmin']['value'] && userRole === Roles.CUSTOMER && (
						<div className="margin-checkbox checkbox-label">
							<NetcurioFormGroup>
								<NetcurioFormControlLabel
									control={
										<NetcurioCheckbox
											onChange={(event) => {
												setErrorUsersForm((prevState) => ({
													...prevState,
													activeInitialError: true
												}))
												setFormUser((prevState) => ({
													...prevState,
													enrollmentRequestApprover:
														!prevState.enrollmentRequestApprover
												}))
											}}
										/>
									}
									label={t('enrollmentManager')}
								/>
							</NetcurioFormGroup>
						</div>
					)}
					<div className="margin-top-25">
						<NetcurioFormControl fullWidth>
							<NetcurioInputLabel id="role">{t('role')}</NetcurioInputLabel>
							<NetcurioSelect
								labelId="role"
								variant="outlined"
								label={t('role')}
								displayEmpty
								fullWidth
								size="medium"
								value={formUser.role}
								onChange={(event) => {
									setFormUser((prevState) => ({
										...prevState,
										role: event.target.value
									}))
								}}
								height={'smaller'}
							>
								<NetcurioMenuItem value={t('placeholderRolDropdown')}>
									<em>{t('placeholderRolDropdown')}</em>
								</NetcurioMenuItem>
								{rolesItems.map((item, i) => (
									<NetcurioMenuItem key={i} value={item.value as string}>
										<span>{item.label}</span>
									</NetcurioMenuItem>
								))}
							</NetcurioSelect>
						</NetcurioFormControl>
						{errorUserForm.missingFields && (
							<span className="message-error">{t('missingFields')} </span>
						)}
					</div>
					<div className="margin-top-25">
						<label className="align-name-text pebbleGrayText">{t('companyText')}</label>
						<div className="container-company">{getCurrentUser().company.name}</div>
					</div>
					<div className="margin-top-25">
						<span className="pebbleGrayText">{t('informationSendInviteText')}</span>
					</div>
				</div>
			</div>
			{serverErrorMessage && (
				<div className="margin-top-25">
					<NetcurioAlert severity={Severity.Error}>{serverErrorMessage}</NetcurioAlert>
				</div>
			)}
		</>
	)
}
